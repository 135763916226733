import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { CircularProgress, MenuItem } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { initialEmpty } from "./EmptyData";
import { emptyGuest } from "./EmptyData";
import { createCheckIn } from "../Context/CheckInContext";
import { useTranslation } from "react-i18next";
import { array, number, object, string } from "yup";
import ENV from "../../environment";

const CheckIn = (props) => {
  const { t } = useTranslation()
  const [data, setData] = useContext(createCheckIn);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = React.useState([]);
  const qy = document.querySelector.bind(document);
  console.log(props)
  const DocumentRegEx = /^[A-Z0-9_ ]{1,100}$/;

  const checkSchema = object({
    room_id: string().required(t("Field is required")),
    start_date: string().required(t("Field is required")),
    end_date: string().required(t("Field is required")),
    room_category: string().required(t("Field is required")),
    amount: number().required(t("Field is required")).test(
      'Is positive?',
      (t("Enter only positive number")),
      (value) => value >= 0
    ),
    discount: number().required(t("Field is required")).test(
      'Is positive?',
      (t("Enter only positive number")),
      (value) => value >= 0
    ),
    credit: number().test(
      'Is positive?',
      (t("Enter only positive number")),
      (value) => value >= 0
    ),
    comments: string().optional(t("Field is required")),
    guests: array(
      object({
        first_name: string().required(t("Field is required")),
        last_name: string().required(t("Field is required")),
        email: string().email().optional(t("Field is required")),
        phone: string().optional(t("Field is required")),
        document_type: string().optional(t("Field is required")),
        document: string().optional(t("Field is required")).matches(DocumentRegEx, { message: t("value invalid") }),
        document_attachment: string().optional(t("Field is required")),
      })
    ),
  });

  initialEmpty.start_date = data?.start_date ? data.start_date.slice(0, 10) : initialEmpty.start_date;
  initialEmpty.end_date = data?.end_date ? data.end_date.slice(0, 10) : initialEmpty.end_date;
  initialEmpty.room_category = data?.room_category ? data.room_category : initialEmpty.room_category;
  initialEmpty.room_id = data?.room_id ? data.room_id : initialEmpty.room_id;
  initialEmpty.discount = data?.discount ? data.discount : initialEmpty.discount || 0;
  initialEmpty.amount = data?.amount ? data.amount : initialEmpty.amount || 0;
  initialEmpty.credit = data?.credit ? data.credit : initialEmpty.credit || 0;
  initialEmpty.comments = data?.comments ? data.comments : initialEmpty.comments;
  emptyGuest.first_name = data?.first_name ? data.first_name : emptyGuest.first_name;
  emptyGuest.last_name = data?.last_name ? data.last_name : emptyGuest.last_name;

  const getCategories = async () => {
    await axios
      .get(`${ENV.API_URL}/api/hdp/room/category`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      }).then((res) => { setCategories(res.data.data) })
      .catch((err) => { toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")) });
  };
  const getRooms = async () => {
    if (data?.room_category) {
      await axios.get(`${ENV.API_URL}/api/hdp/room/cat/${data?.room_category}/available`,
        { headers: { Authorization: sessionStorage.getItem("token") } })
        .then((res) => setRooms(res.data.data))
        .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
    }
  };

  const onChange = async (e, values) => {
    values.room_category = e.target.value;
    if (e) {
      values.room_id = "";
      await axios.get(`${ENV.API_URL}/api/hdp/room/cat/${e.target.value}/available`,
        { headers: { Authorization: sessionStorage.getItem("token") } })
        .then((res) => setRooms(res.data.data))
        .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
    }
  };

  const onClearData = () => {
    setData([])
    initialEmpty.start_date = "";
    initialEmpty.end_date = "";
    initialEmpty.room_category = "";
    initialEmpty.room_id = "";
    initialEmpty.amount = "";
    initialEmpty.discount = "";
    initialEmpty.comments = "";
    emptyGuest.first_name = "";
    emptyGuest.last_name = "";
  };
  //
  const handleSubmit = async (event, { resetForm }) => {
    if (!event?.comments?.length) delete event.comments;
    if (!event?.email?.length) delete event.email;
    if (!event?.phone?.length) delete event.phone;
    if (!event?.document_type?.length) delete event.document_type;
    if (!event?.document?.length) delete event.document;
    if (!event?.document_attachment?.length) delete event.document_attachment;

    const body = new FormData();
    if (data?.id) body.append("id", data.id);

    body.append("room_id", event.room_id);
    body.append("room_category", event.room_category);
    body.append("start_date", event.start_date);
    body.append("end_date", event.end_date);
    body.append("debit", event.amount);
    body.append("credit", event.credit);
    body.append("discount", event.discount / 100);
    body.append("comments", event.comments);
    body.append("guestsLength", event.guests.length);
    event.guests.forEach((el, i) => {
      body.append(`guests[${i}].first_name`, el.first_name);
      body.append(`guests[${i}].last_name`, el.last_name);
      body.append(`guests[${i}].email`, el.email);
      body.append(`guests[${i}].phone`, el.phone);
      body.append(`guests[${i}].document_type`, el.document_type);
      body.append(`guests[${i}].document`, el.document);
      body.append(`guests[${i}].residency`, el.residency);
      body.append(`guests[${i}].document_attachment`, qy(`#doc${i}file`).files[0]);
    });

    setLoading(true);
    const url = ENV.API_URL;
    await axios.post(url + "/api/hdp/check-in", body, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => {
        setData([]);
        onClearData();
        toast.success(t("Saved successfully"));
        resetForm({ values: "" });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
      }).finally(() => {
        if (props?.getRoomdata) {
          props?.getRoomdata()
        }

      });
  };

  const onCalculateTotal = (e, values) => {
    values.room_id = e.target.value;
    rooms.map((value, index) => {
      if (value.id === values.room_id) {
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if (days === 0) values.amount = value.price;
        else values.amount = days * value.price;
      }
    });
  };

  const onStartDate = (e, values) => {
    values.start_date = e.target.value;
    rooms.map((value, index) => {
      if (value.id === values.room_id) {
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if (days === 0) values.amount = value.price;
        else values.amount = days * value.price;
      }
    });
  };
  const onEndDate = (e, values) => {
    values.end_date = e.target.value;
    rooms.map((value, index) => {
      if (value.id === values.room_id) {
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if (days === 0) values.amount = value.price;
        else values.amount = days * value.price;
      }
    });
  };

  useEffect(() => {
    getCategories();
    getRooms();
  }, []);

  const today = new Date()?.toISOString()?.split("T")[0];
  document?.getElementById("date-input1")?.setAttribute("min", today);
  document?.getElementById("date-input2")?.setAttribute("min", today);

  return (
    <div className="d-flex justify-content-center flex-column container py-3 check_scroll">
      <div className="py-2">
        <div className="card p-3">
          <div className="card-header">
            <h5 className="text-center">{t("Enter check in info")}</h5>
            {loading && <div className="overlay" />}
          </div>

          <Formik
            initialValues={initialEmpty}
            validationSchema={checkSchema}
            onSubmit={async (values, { resetForm }) => {
              handleSubmit(values, { resetForm });
            }}
          >
            {({ values, errors }) => (
              <Form autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <label>{t("Check in date")}</label>
                    <Field
                      id="date-input1"
                      onInput={(e) => onStartDate(e, values)}
                      fullWidth
                      size="small"
                      name="start_date"
                      component={TextField}
                      type={"date"}
                      disabled={data.id ? true : false}
                      min=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>{t("Check out date")}</label>
                    <Field
                      id="date-input2"
                      onInput={(e) => onEndDate(e, values)}
                      fullWidth
                      size="small"
                      name="end_date"
                      component={TextField}
                      type={"date"}
                      disabled={data.id ? true : false}
                      min=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth
                      select
                      name="room_category"
                      size="small"
                      component={TextField}
                      label={t("Select Category")}
                      onChange={(e) => onChange(e, values)}
                    >
                      {categories.map((item, index) => {
                        return (
                          <MenuItem value={item.id} key={index}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      onBlur={(e) => onCalculateTotal(e, values)}
                      fullWidth
                      select
                      name="room_id"
                      size="small"
                      component={TextField}
                      label={t("Select Room")}
                      disabled={!values.room_category ? true : false}
                    >
                      {rooms.map((item, index) => {
                        return (
                          <MenuItem value={item.id} key={index}>
                            {item.number}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field fullWidth size="small" onWheel={(e) => e.target.blur()} name="amount" component={TextField} label={t("Amount")} type={"number"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field fullWidth size="small" name="credit" onWheel={(e) => e.target.blur()} component={TextField} label={t("Credit")} type={"number"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field fullWidth size="small" name="discount" onWheel={(e) => e.target.blur()} component={TextField} label={t("Discount")} type={"number"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field fullWidth size="small" name="comments" label={t("Comments")} component={TextField} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid xs={12} sm={12}>
                      <div className="card-header my-3">
                        <h5 className="text-center">{t("Enter Guests info")}</h5>
                      </div>
                    </Grid>
                    {data?.start_date?.length ? (
                      <Button fullWidth color="warning" disabled={loading} variant="outlined" onClick={() => onClearData()}>
                        {t("Clear Booking info")}
                      </Button>
                    ) : null}
                  </Grid>
                  <FieldArray name="guests">
                    {({ push, remove }) => (
                      <React.Fragment>
                        {values.guests?.map((guest, index) => (
                          <Grid container item key={index} spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                fullWidth
                                size="small"
                                label={t("First name")}
                                name={`guests[${index}].first_name`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                fullWidth
                                size="small"
                                label={t("Last name")}
                                name={`guests[${index}].last_name`}
                                component={TextField}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                size="small"
                                fullWidth
                                select
                                component={TextField}
                                name={`guests[${index}].document_type`}
                                label={t("Document Type")}
                              >
                                <MenuItem value={1}>Passport</MenuItem>
                                <MenuItem value={2}>ID</MenuItem>
                                <MenuItem value={3}>Driver’s license</MenuItem>
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field fullWidth size="small" name={`guests[${index}].document`} label={t("Document number")} component={TextField} />
                            </Grid>
                            <div className="card w-100 mt-3 left_padding">
                              <div className="card-body">
                                <h6 className="card-title">{t("Optional Guest Info")}</h6>
                                <div className="card-body-wrapper">
                                  <Grid item xs={12} sm={6} marginRight={"7px"} className="checkin_optional_data_item_left">
                                    <Field fullWidth size="small" label={t("Email")} name={`guests[${index}].email`} component={TextField} />
                                  </Grid>
                                  <br />
                                  <Grid item xs={12} sm={6} marginLeft={"7px"} className="checkin_optional_data_item_right">
                                    <Field fullWidth size="small" name={`guests[${index}].phone`} label={t("Phone")} component={TextField} />
                                  </Grid>
                                </div>
                                <Grid item xs={12} sm={12}>
                                  <Field id={`doc${index}file`} fullWidth size="small" name={`guests[${index}].document_attachment`} component={TextField} type="file" />
                                </Grid>
                              </div>
                            </div>
                            <Grid item xs={12}>
                              <Button color="primary" disabled={loading} variant="contained" onClick={() => push(emptyGuest)}>
                                {t("Add more guest")}
                              </Button>
                              <Button variant="contained" color="error" size="small" className="btn btn-danger float-end" disabled={loading}
                                style={index === 0 ? { display: "none" } : { display: "block" }} onClick={() => remove(index)}>{t("Delete")}
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </React.Fragment>
                    )}
                  </FieldArray>
                </Grid>
                <Button startIcon={loading && <CircularProgress size="0.9rem" />}
                  disabled={loading} fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}
                >
                  {t("Submit")}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CheckIn;
