import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import Loading from "../Loading.jsx";
import { LoadingButton } from "@mui/lab";
import { t } from "i18next";
import ENV from "../../environment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TransitionsModal({ id, getTableData, close }) {
  const [categories, setCategories] = React.useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [wait, setWait] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getCategories = async () => {
    await axios
      .get(`${ENV.API_URL}/api/hdp/room/category`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  const getData = async () => {
    setWait(true);
    await axios
      .get(`${ENV.API_URL}/api/hdp/room/${id}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setData(res.data.data);
        setWait(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        setWait(false);
      });
  };

  const validate = Yup.object({
    category: Yup.string().required("category is required"),
    number: Yup.string().required("number is required"),
  });

  var emptyData = data;

  const onUpdate = async (values) => {
    setLoading(true);
    if (values?.hotel_id && values?.status) {
      delete values.hotel_id;
      delete values.status;
    }
    const body = {
      id: values?.id,
      category: values?.category,
      number: values?.number.toString(),
    };
    await axios
      .put(`${ENV.API_URL}/api/hdp/room`, body, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        toast.success(t("Updated successfully"));
        handleClose();
        close();
        getTableData();
        getData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  useEffect(() => {
    getCategories();
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Typography onClick={handleOpen} title="Click">
        {t("Update")}
      </Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          {data === emptyData && (
            <Box sx={style} className="modal_res">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className="marginBottom"
              >
                {t("Update room info")}
              </Typography>
              {wait ? (
                <Loading />
              ) : (
                <Formik
                  initialValues={data}
                  validationSchema={validate}
                  onSubmit={async (values) => {
                    onUpdate(values);
                  }}
                >
                  {({ values, errors }) => (
                    <Form autoComplete="off" className="mt-4">
                      <>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <Field
                              fullWidth
                              select
                              name="category"
                              size="small"
                              defaultValue={data.category}
                              component={TextField}
                              label={t("Select Category")}
                            >
                              {categories.map((item, index) => (
                                <MenuItem value={item.id} key={index}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              fullWidth
                              name="number"
                              size="small"
                              defaultValue={data.number}
                              component={TextField}
                              label={t("Room number")}
                              type="number"
                            />
                          </Grid>
                        </Grid>
                      </>
                      <div className="right_left">
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          color="error"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          {t("Close")}
                        </Button>
                        <>
                          {loading ? (
                            <LoadingButton
                              loading
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              color="success"
                            >
                              {t("Submit")}
                            </LoadingButton>
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              color="success"
                            >
                              {t("Submit")}
                            </Button>
                          )}
                        </>
                        {loading && <div className="overlay" />}
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );
}

export default React.memo(TransitionsModal);
