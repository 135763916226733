import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { toast } from "react-toastify";
import axios from "axios";
import environment from "../../../environment";
import Loading from "../../Loading";
import DropDown from "./DropDown";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const headCells = [
    { id: "room_number", numeric: true, label: t("Room number") },
    { id: "first_name", numeric: true, label: t("First name") },
    { id: "last_name", numeric: true, label: t("Last name") },
    { id: "checked_date", numeric: true, label: t("Checked date") },
    { id: "checkout_date", numeric: true, label: t("Checkout date") },
    { id: "more-info", numeric: true, label: t("More info") },
  ];

  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTable() {
  const [count, setCount] = useState(0);
  const [oy, setOy] = useState(1);
  const [loading, setLoading] = useState(true);
  const [guests, setGuests] = useState([]);
  const [error, setError] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    getData({page:newPage+1});
    setPage(newPage);
  };

  const getData = async ({page, limit, mouth}) => {
    setError(false);
    setLoading(true);
    await axios
      .get(
        `${environment.API_URL}/api/hdp/guest/month/${mouth || oy}?page=${page || 1}&limit=${limit || 10}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setCount(res.data.count);
        setGuests(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        setError(true);
        setLoading(false);
      });
  };

  const onMouth = async (e) => {
    setPage(0)
    if(e?.target?.value){
        setOy(e.target.value)
    }
      getData({mouth:e?.target?.value});
  };
  const onSearch = async (e) => {
    setPage(0)
    if (e?.target?.value === "") {
      onMouth();
    } else {
      setLoading(true);
      await axios
        .get(
          `${environment.API_URL}/api/hdp/guest/checked-out/search?query=${e?.target?.value}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setGuests(res.data.data);
          setCount(res.data.data.length)
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getData({});
  }, []);

  return (
    <div className="container mt-3">
      <div className="my-3 d-flex responsive-search-wrapper">
        <h6 className="mb-2 text-primary">{t("Filter")}</h6>
        <select
          onChange={onMouth}
          placeholder={t("Select month")}
          className="form-control mx-2 responsive-search-wrapper-item"
          size="small"
        >
          <option value={1}>{t("January")}</option>
          <option value={2}>{t("February")}</option>
          <option value={3}>{t("March")}</option>
          <option value={4}>{t("April")}</option>
          <option value={5}>{t("May")}</option>
          <option value={6}>{t("June")}</option>
          <option value={7}>{t("July")}</option>
          <option value={8}>{t("August")}</option>
          <option value={9}>{t("September")}</option>
          <option value={10}>{t("October")}</option>
          <option value={11}>{t("November")}</option>
          <option value={12}>{t("December")}</option>
        </select>
        <input
          type="text"
          placeholder={t("Room number, First name, Last name")}
          className="form-control mx-2 responsive-search-wrapper-item"
          onChange={onSearch}
        />
      </div>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        {loading ? (
          <Loading />
        ) : !error && guests.length === 0 ? (
          <Alert variant="outlined" severity="warning">
            {t("No Data yet")}
          </Alert>
        ) : error ? (
          <Alert variant="outlined" severity="error">
            {t("An error occurred")}
          </Alert>
        ) : (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750, px: 2 }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={count}
                />
                <TableBody>
                  {stableSort(guests, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          <TableCell align="left">{row.room_number}</TableCell>
                          <TableCell align="left">{row.first_name}</TableCell>
                          <TableCell align="left">{row.last_name}</TableCell>
                          <TableCell align="left">
                            {row.checked_date?.slice(0, 10)}
                          </TableCell>
                          <TableCell align="left">
                            {row.checkout_date?.slice(0, 10)}
                          </TableCell>
                          <TableCell align="left">
                            <DropDown data={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </Paper>
        )}
      </Box>
    </div>
  );
}

export default React.memo(EnhancedTable);
