import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Loading";
import ChangePassword from "./ChangePassword";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import avatar from "../../assets/guestAvatar.png";
import styles from "../GuestInfo/styles.module.css";

const Profile = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const getData = async () => {
    await axios
      .get(`${ENV.API_URL}/api/hdp/user/me`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container mt-5">
      {loading ? (
        <Loading />
      ) : (
        <div className="card h-100">
          <div className="card-body">
            <div className="row mt-4">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mb-2 text-primary">{t("Personal Details")}</h6>
              </div>
              {/* {data?.id && (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t("Id")}</label>
                    <input
                      type="text"
                      value={data.id}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              )}
              {data?.first_name && (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t("First name")}</label>
                    <input
                      type="text"
                      value={data.first_name}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              )}
              {data?.last_name && (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t("Last name")}</label>
                    <input
                      type="text"
                      value={data.last_name}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              )}
              
              {data?.phone && (
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t("Phone")}</label>
                    <input
                      type="text"
                      value={data.phone}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              )}
              {data?.email && (
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t("Email")}</label>
                    <input
                      type="text"
                      value={data.email}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              )} */}

              <div className={styles.guest} key={data?.id||""}>
                <img src={avatar} alt="avatar" className={styles.guestImage} />
                <div className={styles.guestName}>
                  Id:{data?.id||""}
                </div>
                <div className={styles.guestName}>
                  {data?.first_name||""} {data?.last_name||""} {data?.middle_name||""}
                </div>
                {(data?.phone || data?.email) && (
                  <div className={styles.guestContact}>
                    {data?.email} {data?.phone && data?.email && "/"} {data?.phone}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h6 className="mt-3 mb-2 text-primary">
                    {t("Change password")}
                  </h6>
                </div>
                <ChangePassword />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
