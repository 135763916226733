import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import styles2 from "./booking-styles.module.css";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, Form, Formik } from "formik";
import { AntDatePicker, AntInput, AntSelect } from "../../../hooks/createAntDFields";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { TextField } from "formik-material-ui";
import ENV from "../../../environment";
import { dateDDMMYYYY, dateLocalISO } from "../../../hooks/dateFormatter";
import dayjs from "dayjs";

const guestInitialValue = {
    document_attachment: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    phone: "",
    document_type: "",
    document: "",
};

const chechInGuestInitialValue = {
    room_category: "",
    room_id: "",
    start_date: "",
    end_date: "",
    amount: "",
    comments: "",
    guests: [guestInitialValue],
};

const document_types = [
    { value: 1, label: "Passport" },
    { value: 2, label: "ID" },
    { value: 3, label: "Driver’s license" },
];

export default function ShaxmatkaCheckIn({ setShow, show, checkInData, getData, categories }) {
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);

    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        // if (e.currentTarget === e.target) hide();
    };
    const hide = (resetForm) => {
        setShowTrue(false);
        if (resetForm) resetForm();
        setTimeout(() => setShow(false), 300);
    };

    const [cats, setCats] = useState(categories.map((e) => ({ value: e.id, label: e.name })));
    const [rooms, setRooms] = useState([]);
    const [catWaiting, setCatWaiting] = useState(false);

    useEffect(() => {
        setCats(categories.map((e) => ({ value: e.id, label: e.name })));
    }, []);

    const changeCategoryHandler = async (cat, values, setValues) => {
        if (cat) {
            setCatWaiting(true);
            if (setValues) {
                values.room_category = cat;
                values.room_id = "";
            }
            await axios
                .get(`${ENV.API_URL}/api/hdp/room/cat/${cat}`, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    if (setValues) setValues(values);
                    setRooms(res.data.data.map((e) => ({ value: e.id, label: e.number })));
                })
                .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
            setCatWaiting(false);
        }
    };

    const [initialValue, setInitialValue] = useState(chechInGuestInitialValue);
    useEffect(() => {
        chechInGuestInitialValue.start_date = dayjs(dateDDMMYYYY(checkInData.start_date || new Date()), "DD.MM.YYYY");
        if (checkInData.end_date) chechInGuestInitialValue.end_date = dayjs(dateDDMMYYYY(checkInData.end_date), "DD.MM.YYYY");
        else chechInGuestInitialValue.end_date = "";
        chechInGuestInitialValue.debit = 0;
        chechInGuestInitialValue.credit = 0;
        if (checkInData.room) {
            chechInGuestInitialValue.room_category = checkInData.room.category.id;
            chechInGuestInitialValue.room_id = checkInData.room.id;
            changeCategoryHandler(checkInData.room.category.id);
        }
        guestInitialValue.first_name = checkInData.first_name || "";
        guestInitialValue.last_name = checkInData.last_name || "";
        guestInitialValue.middle_name = checkInData.middle_name || "";
        setInitialValue(chechInGuestInitialValue);
    }, [checkInData]);

    const checkSchema = Yup.object({
        room_id: Yup.string().required(t("Field is required")),
        start_date: Yup.string().required(t("Field is required")),
        end_date: Yup.string().required(t("Field is required")),
        room_category: Yup.string().required(t("Field is required")),
        credit: Yup.string().required(t("Field is required")),
        discount: Yup.number().min(0, "min: 0%").max(100, "max: 100%"),
        comments: Yup.string().optional(t("Field is required")),
        guests: Yup.array(
            Yup.object({
                first_name: Yup.string().required(t("Field is required")),
                last_name: Yup.string().required(t("Field is required")),
                middle_name: Yup.string().optional(),
                email: Yup.string().email().optional(t("Field is required")),
                phone: Yup.string().optional(t("Field is required")),
                document_type: Yup.string().optional(t("Field is required")),
                document: Yup.string()
                    .optional(t("Field is required"))
                    .matches(/^[A-Z0-9_ ]{1,100}$/, { message: t("value invalid") }),
                document_attachment: Yup.string().optional(t("Field is required")),
            })
        ),
    });

    const qy = document.querySelector.bind(document);
    const submit = async (event, { resetForm }, setSubmitting) => {
        if (!event.start_date.$d || new Date(dateLocalISO(event.start_date?.$d)) > new Date(dateLocalISO(new Date())))
            return toast.error("Start date is invalid");
        if (!event.end_date.$d || new Date(dateLocalISO(event.start_date?.$d)) >= new Date(dateLocalISO(event.end_date?.$d)))
            return toast.error("End date is invalid");
        if (!event?.comments?.length) delete event.comments;
        if (!event?.middle_name?.length) delete event.middle_name;
        if (!event?.email?.length) delete event.email;
        if (!event?.document_type?.length) delete event.document_type;
        if (!event?.document?.length) delete event.document;
        if (!event?.phone?.length) delete event.phone;
        if (!event?.document_attachment?.length) delete event.document_attachment;

        const body = new FormData();
        if (checkInData.booking_id) body.append("id", checkInData.booking_id);

        body.append("room_id", event.room_id);
        body.append("room_category", event.room_category);
        body.append("start_date", dateLocalISO(event.start_date?.$d));
        body.append("end_date", dateLocalISO(event.end_date?.$d));
        // body.append("debit", checkInData.room?.price * dateDiffInDays(event.start_date?.$d, event.end_date?.$d) || 0);
        body.append("debit", event.debit);
        body.append("credit", event.credit);
        if (event.discount) {
            if (event.discount > 100) body.append("discount", 100);
            else if (event.discount < 0) body.append("discount", 0);
            else body.append("discount", parseFloat(event.discount) / 100);
        }
        body.append("comments", event.comments);
        body.append("guestsLength", event.guests.length);
        event.guests.forEach((el, i) => {
            body.append(`guests[${i}].first_name`, el.first_name);
            body.append(`guests[${i}].last_name`, el.last_name);
            body.append(`guests[${i}].middle_name`, el.middle_name);
            body.append(`guests[${i}].email`, el.email);
            body.append(`guests[${i}].phone`, el.phone);
            body.append(`guests[${i}].document_type`, el.document_type);
            body.append(`guests[${i}].document`, el.document);
            body.append(`guests[${i}].residency`, el.residency);
            body.append(`guests[${i}].document_attachment`, qy(`#doc${i}file`).files[0]);
        });

        await axios
            .post(`${ENV.API_URL}/api/hdp/check-in`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(() => {
                setShow(false);
                getData();
                toast.success(t("Saved successfully"));
                resetForm();
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
        setSubmitting(false);
    };

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <Formik initialValues={initialValue} validationSchema={checkSchema} onSubmit={submit}>
                {({ values, isSubmitting, submitCount, resetForm, setValues }) => (
                    <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                        <div className={styles.title}>
                            <span className={styles.close} onClick={() => hide(resetForm)}>
                                &#x2715;
                            </span>
                        </div>
                        <h5 style={{ padding: "10px" }}>{t("Check in")}</h5>
                        <div></div>
                        <Form autoComplete="off">
                            <div className={`${styles2.bookingContainer} bookingContainer`}>
                                <div className={styles2.bookingKey}>{t("Category")}</div>
                                <Field
                                    component={AntSelect}
                                    name="room_category"
                                    submitCount={submitCount}
                                    options={cats}
                                    onSelect={(e) => changeCategoryHandler(e, values, setValues)}
                                />
                                <div className={styles2.bookingKey}>{t("Room")}</div>
                                <Field component={AntSelect} name="room_id" submitCount={submitCount} options={rooms} disabled={catWaiting} />
                                <div className={styles2.bookingKey}>{t("Start date")}</div>
                                <Field component={AntDatePicker} name="start_date" submitCount={submitCount} format={"DD.MM.YYYY"} allowClear={false} />
                                <div className={styles2.bookingKey}>{t("End date")}</div>
                                <Field component={AntDatePicker} name="end_date" submitCount={submitCount} format={"DD.MM.YYYY"} allowClear={false} />
                                <hr />
                                <hr />
                                <div className={styles2.bookingKey}>{t("Amount")}</div>
                                <div className="small">
                                    {values.end_date?.$d &&
                                        t("price: ") +
                                            Math.round(
                                                checkInData.room?.price *
                                                    (1 - (values.discount || 0) / 100) *
                                                    dateDiffInDays(values.start_date?.$d, values.end_date?.$d)
                                            )}
                                    <Field component={AntInput} name="debit" submitCount={submitCount} />
                                </div>
                                <div className={styles2.bookingKey}>{t("Credit")}</div>
                                <Field component={AntInput} name="credit" submitCount={submitCount} />
                                <div className={styles2.bookingKey}>{t("Discount")}</div>
                                <Field component={AntInput} name="discount" submitCount={submitCount} />
                                <div className={styles2.bookingKey}>{t("Comment")}</div>
                                <Field component={AntInput} name="comments" submitCount={submitCount} />
                                <hr />
                                <hr />

                                <FieldArray name="guests">
                                    {({ push, remove }) => (
                                        <React.Fragment>
                                            {values.guests?.map((guest, index) => (
                                                <React.Fragment key={index}>
                                                    <div className={styles2.bookingKey}>{t("First name")}</div>
                                                    <Field component={AntInput} name={`guests[${index}].first_name`} submitCount={submitCount} />
                                                    <div className={styles2.bookingKey}>{t("Last name")}</div>
                                                    <Field component={AntInput} name={`guests[${index}].last_name`} submitCount={submitCount} />
                                                    <div className={styles2.bookingKey}>{t("Middle name")}</div>
                                                    <Field component={AntInput} name={`guests[${index}].middle_name`} submitCount={submitCount} />
                                                    <div className={styles2.bookingKey}>{t("Document Type")}</div>
                                                    <Field
                                                        component={AntSelect}
                                                        name={`guests[${index}].document_type`}
                                                        submitCount={submitCount}
                                                        options={document_types}
                                                    />
                                                    <div className={styles2.bookingKey}>{t("Document number")}</div>
                                                    <Field component={AntInput} name={`guests[${index}].document`} submitCount={submitCount} />
                                                    <div className={styles2.bookingKey}>{t("Document")}</div>
                                                    <Field
                                                        id={`doc${index}file`}
                                                        size="small"
                                                        name={`guests[${index}].document_attachment`}
                                                        component={TextField}
                                                        type="file"
                                                    />
                                                    <div className={styles2.bookingKey}>{t("Email")}</div>
                                                    <Field component={AntInput} name={`guests[${index}].email`} submitCount={submitCount} />
                                                    <div className={styles2.bookingKey}>{t("Phone")}</div>
                                                    <Field component={AntInput} name={`guests[${index}].phone`} submitCount={submitCount} />

                                                    <div>
                                                        {index === values.guests.length - 1 && values.guests.length < checkInData?.room?.number_of_people && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                onClick={() => push(guestInitialValue)}
                                                            >
                                                                {t("Add more guest")}
                                                            </Button>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {index !== 0 && (
                                                            <Button
                                                                danger
                                                                variant="contained"
                                                                className="float-end"
                                                                disabled={isSubmitting}
                                                                onClick={() => remove(index)}
                                                            >
                                                                {t("Delete")}
                                                            </Button>
                                                        )}
                                                    </div>
                                                    {index !== values.guests.length - 1 && (
                                                        <React.Fragment>
                                                            <hr />
                                                            <hr />
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </div>
                            <Button style={{ float: "right", marginRight: "10px" }} type="primary" htmlType="submit">
                                {isSubmitting ? t("Submitting") : t("Submit")}
                            </Button>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
}

function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
