import React, { useEffect, useState } from "react";
import { Button, Grid, Radio, RadioGroup } from "@mui/material";
import { FormControl, FormControlLabel, FormLabel } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import axios from "axios";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import CancelIcon from "@mui/icons-material/Cancel";
import ENV from "../../environment";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const HotelPolicy = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getData = async () => {
        await axios
            .get(`${ENV.API_URL}/api/hdp/hotel/policy`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoadingData(false);
                setData(res.data.data.data);
            })
            .catch((err) => {
                setLoadingData(false);
                toast.error(t(err?.response?.data?.errors[0]?.message || "An error occurred"));
            });
    };

    const validate = Yup.object({
        check_in_time: Yup.string().required(t("Field is required")),
        check_out_time: Yup.string().required(t("Field is required")),
        free_wifi: Yup.string().required(t("Field is required")),
        kids_stay_free: Yup.string().required(t("Field is required")),
        free_parking: Yup.string().required(t("Field is required")),
        allow_pets: Yup.string().required(t("Field is required")),
        hourbypay: Yup.string().required(t("Field is required")),
        citizenship: Yup.string().required(t("Field is required")),
    });
    const emptyData = {
        check_in_time: data?.check_in_time ? data.check_in_time : null,
        check_out_time: data?.check_out_time ? data.check_out_time : null,
        free_wifi: data?.free_wifi ? data.free_wifi : false,
        kids_stay_free: data?.kids_stay_free ? data.kids_stay_free : false,
        free_parking: data?.free_parking ? data.free_parking : false,
        allow_pets: data?.allow_pets ? data.allow_pets : false,
        hourbypay: data?.hourbypay ? data.hourbypay : false,
        citizenship: data?.citizenship ? data.citizenship : false,
    };

    const onSubmit = async (values, { resetForm }) => {
        setLoading(true);
        const body = {
            check_in_time: values?.check_in_time,
            check_out_time: values?.check_out_time,
            free_wifi: values?.free_wifi,
            kids_stay_free: values?.kids_stay_free,
            free_parking: values?.free_parking,
            allow_pets: values?.allow_pets,
            hourbypay: values?.hourbypay,
            citizenship: values?.citizenship,
        };
        if (!data?.check_in_time) {
            await axios
                .post(`${ENV.API_URL}/api/hdp/hotel/policy`, body, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    setLoading(false);
                    getData();
                    toast.success(t("Saved successfully"), { autoClose: 2000 });
                    resetForm({ values: "" });
                    handleClose();
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(t(err?.response?.data?.errors[0]?.message || "An error occurred"));
                });
        }
        await axios
            .put(`${ENV.API_URL}/api/hdp/hotel/policy`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                getData();
                toast.success(t("Saved successfully"), { autoClose: 2000 });
                resetForm({ values: "" });
                handleClose();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t(err?.response?.data?.errors[0]?.message || "An error occurred"));
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {loadingData ? (
                <Loading />
            ) : (
                <div className="card container p-0 mt-3">
                    <div className="card-header d-flex justify-content-center">
                        <h5 className="card-title text-primary">{t("List of policies")}</h5>
                    </div>
                    <div className="card-body">
                        {data?.check_in_time ? (
                            <div className="list-group">
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Check in time")}: {data?.check_in_time?.slice(0, 5)}
                                </button>
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Check out time")}: {data?.check_out_time?.slice(0, 5)}
                                </button>
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Free Wi-Fi")}:{" "}
                                    {data?.free_wifi === true ? (
                                        <span className="text-success">
                                            {t("Free Wi-Fi")}. <DownloadDoneIcon />
                                        </span>
                                    ) : (
                                        <s className="text-danger">
                                            {t("Free Wi-Fi")}. <CancelIcon />
                                        </s>
                                    )}
                                </button>
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Kids stay free")}:{" "}
                                    {data?.kids_stay_free === true ? (
                                        <span className="text-success">
                                            {t("Kids stay free")}. <DownloadDoneIcon />
                                        </span>
                                    ) : (
                                        <s className="text-danger">
                                            {t("Kids stay free")}. <CancelIcon />
                                        </s>
                                    )}
                                </button>
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Free parking")}:{" "}
                                    {data?.free_parking === true ? (
                                        <span className="text-success">
                                            {t("Free parking")}. <DownloadDoneIcon />
                                        </span>
                                    ) : (
                                        <s className="text-danger">
                                            {t("Free parking")}. <CancelIcon />
                                        </s>
                                    )}
                                </button>
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Allow pets")}:{" "}
                                    {data?.allow_pets === true ? (
                                        <span className="text-success">
                                            {t("Allow pets")}. <DownloadDoneIcon />
                                        </span>
                                    ) : (
                                        <s className="text-danger">
                                            {t("Allow pets")}. <CancelIcon />
                                        </s>
                                    )}
                                </button>
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Hour by pay")}:{" "}
                                    {data?.hourbypay === true ? (
                                        <span className="text-success">
                                            {t("Hour by pay")}. <DownloadDoneIcon />
                                        </span>
                                    ) : (
                                        <s className="text-danger">
                                            {t("Hour by pay")}. <CancelIcon />
                                        </s>
                                    )}
                                </button>
                                <button type="button" className="list-group-item list-group-item-action" disabled>
                                    {t("Citizenship")}:{" "}
                                    {data?.citizenship === true ? (
                                        <span className="text-success">
                                            {t("Citizenship")}. <DownloadDoneIcon />
                                        </span>
                                    ) : (
                                        <s className="text-danger">
                                            {t("Citizenship")}. <CancelIcon />
                                        </s>
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div className="alert alert-warning" role="alert">
                                {t("No policies listed at this time!")}
                            </div>
                        )}
                        <Button className="float-end mt-3" variant="contained" color="primary" onClick={handleOpen}>
                            {data?.check_in_time ? t("Update Policy") : t("Add Policy")}
                        </Button>
                    </div>
                </div>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="modal_res">
                        <>
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                                {t("Enter policy info")}
                            </Typography>
                            <Formik
                                initialValues={emptyData}
                                validationSchema={validate}
                                onSubmit={async (values, { resetForm }) => {
                                    onSubmit(values, { resetForm });
                                    return new Promise((res) => setTimeout(res, 500));
                                }}
                            >
                                {({ values, errors }) => (
                                    <Form autoComplete="off" className="mt-4">
                                        <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                                <Field fullWidth name="check_in_time" size="small" component={TextField} type="time" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field fullWidth name="check_out_time" size="small" component={TextField} type="time" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="free_wifi" as="div">
                                                    {({ field }) => (
                                                        <>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{t("Free Wi-Fi")}</FormLabel>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value={false} control={<Radio />} label={t("No")} />
                                                                    <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="kids_stay_free" as="div">
                                                    {({ field }) => (
                                                        <>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{t("Kids stay free")}</FormLabel>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value={false} control={<Radio />} label={t("No")} />
                                                                    <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="free_parking" as="div">
                                                    {({ field }) => (
                                                        <>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{t("Free parking")}</FormLabel>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value={false} control={<Radio />} label={t("No")} />
                                                                    <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="allow_pets" as="div">
                                                    {({ field }) => (
                                                        <>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{t("Allow pets")}</FormLabel>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value={false} control={<Radio />} label={t("No")} />
                                                                    <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="hourbypay" as="div">
                                                    {({ field }) => (
                                                        <>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{t("Hour by pay")}</FormLabel>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value={false} control={<Radio />} label={t("No")} />
                                                                    <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field name="citizenship" as="div">
                                                    {({ field }) => (
                                                        <>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{t("Citizenship")}</FormLabel>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value={false} control={<Radio />} label={t("No")} />
                                                                    <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                        <div className="right_left">
                                            <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                                {t("Close")}
                                            </Button>
                                            <>
                                                {loading ? (
                                                    <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        {t("Submit")}
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        {t("Submit")}
                                                    </Button>
                                                )}
                                            </>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>
                        {loading && <div className="overlay" />}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default HotelPolicy;
