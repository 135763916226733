import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import environment from "../../environment";
import Spinner from "../Loading";
import downloading from "../../assets/loading-image-white.gif";
import { toast } from "react-toastify";
import { Modal } from "antd";

const HotelPictures = () => {
    const [loading, setLoading] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [file, setFile] = useState(null);
    const [docs, setDocs] = useState([]);
    const [showing, setShowing] = useState(null);

    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedForDelete, setSelectedForDelete] = useState([]);
    const handleOpenDelete = () => {
        setSelectedForDelete([]);
        setIsDeleting(!isDeleting);
    };
    const onSelect = (uuid) => {
        const index = selectedForDelete.indexOf(uuid);
        if (index === -1) selectedForDelete.push(uuid);
        else selectedForDelete.splice(selectedForDelete.indexOf(uuid), 1);
        setSelectedForDelete([...selectedForDelete]);
    };

    const onDelete = async () => {
        setLoadingDelete(true);

        const headers = { authorization: "Bearer " + sessionStorage.getItem("token"), "client-id": environment.CLIENTID, "client-secret": environment.CLIENTSECRET };
        const promises = selectedForDelete.map(async (el) => {
            return new Promise(async (resolve, reject) => {
                try {
                    await axios
                        .delete(`${environment.API_URL}/api/hdp/hotel/image/${el}`, { headers })
                        .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || "An error occurred"));
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        });
        await Promise.all(promises);

        // setDocs([])
        setIsDeleting(false);
        await getUuid();
        toast.success("Deleted successfully");
        setLoadingDelete(false);
    };

    const getUuid = async () => {
        // setLoading(true);
        let list = [];
        await axios
            .get(`${environment.API_URL}/api/hdp/hotel/images-uuids`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": environment.CLIENTID,
                    "client-secret": environment.CLIENTSECRET,
                },
            })
            .then((res) => {
                console.log(res.data.data);
                res?.data?.data?.map((element) => {
                    list?.push(element.uuid);
                });
                getImg(list);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };

    const getImg = async (list) => {
        const items = [];
        console.log(list);
        for (let i = 0; i < list?.length; i++) {
            const element = list[i];
            const item = { uri: `${environment.API_URL}/api/hdp/hotel/image/${element}`, uuid: element };
            items.push(item);
        }
        setDocs(items);
        setLoading(false);
    };

    useEffect(() => {
        getUuid();
    }, []);

    const headers = { "Content-Type": "application/png", "client-id": environment.CLIENTID, "client-secret": environment.CLIENTSECRET,  Authorization: sessionStorage.getItem("token"), };
    const getImage = async () => {
        for (let i = 0; i < docs?.length; i++) {
            fetch(docs[i]?.uri, { method: "GET", headers })
                .then((res) => res.blob())
                .then((blob) => {
                    if (/image/.test(blob.type) && document.querySelector("#c-" + docs[i]?.uuid))
                        document.querySelector("#c-" + docs[i].uuid).src = window.URL.createObjectURL(blob);
                });
        }
    };

    useEffect(() => {
        getImage();
    }, [docs]);

    const onSubmit = async (e) => {
        // setLoading(true);
        // e.preventDefault();
        if (!file) return;
        docs.push({});
        setDocs([...docs]);
        const formData = new FormData();
        formData.append("file", file);
        await axios
            .post(`${environment.API_URL}/api/hdp/hotel/image`, formData, {
                headers: {
                    authorization: "Bearer " + sessionStorage.getItem("token"),
                    "Content-Type": "multipart/form-data",
                    "client-id": environment.CLIENTID,
                    "client-secret": environment.CLIENTSECRET,
                },
            })
            .then((res) => {
                setFile(null);
                // toast.success("Saved successfully");
                // setLoading(false);
                getUuid();
            })
            .catch((err) => {
                setFile(null);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
                docs.pop()
                setDocs([...docs]);
                // setLoading(false);
            });
    };

    useEffect(() => {
        onSubmit();
    }, [!file]);

    return (
        <div className="d-flex justify-content-center flex-column container py-3 check_scroll">
            {loadingDelete && <div className="overlay" />}

            <div className="px-3 d-flex justify-content-end gap-2">
                {isDeleting && (
                    <Button disabled={selectedForDelete.length === 0} color="error" variant="contained" size="small" onClick={onDelete}>
                        {"Delete"}
                    </Button>
                )}
                <Button onClick={handleOpenDelete} color="primary" variant="contained" size="small">
                    {isDeleting ? "Cancel" : "Select"}
                </Button>
            </div>
            {loading && (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            )}

            <div className="d-flex justify-content-center flex-column container check_scroll">
                <div
                    className="py-2"
                    style={{
                        "--width": docs.length > 2 && docs.length < 5 ? 1256 / (docs.length + 1) - 20 + "px" : "230px",
                        justifyContent: docs.length > 2 && docs.length < 5 ? "space-between" : "start",
                    }}
                >
                    <div className={"card p-3 category-image-container " + (isDeleting && "image-deleting")}>
                        {docs.map((image) => (
                            <div className="category-image-box" onClick={() => isDeleting && onSelect(image.uuid)}>
                                <div className={"no-select deleting-index " + (selectedForDelete.includes(image.uuid) ? "check" : "not-check")}>
                                    {selectedForDelete.includes(image.uuid) && "✓"}
                                </div>
                                <img src={downloading} id={`c-${image.uuid}`} alt="" />
                                {!isDeleting && (
                                    <React.Fragment>
                                        <div className="showing" onClick={() => setShowing(image.uuid)}>
                                            <Eye />
                                        </div>
                                        <Modal title="Image" open={showing === image.uuid} footer={[]} onCancel={() => setShowing(null)} width={600}>
                                            <img alt="" src={document.querySelector("#c-" + image.uuid)?.src} className="modal-image" />
                                        </Modal>
                                    </React.Fragment>
                                )}
                            </div>
                        ))}
                        {docs.length < 5 && (
                            <div className="category-image-box upload-image no-select" onClick={() => document.getElementById("image-input").click()}>
                                <span>+</span>
                                <span>Upload</span>
                                <input onChange={(e) => setFile(e.target.files[0])} hidden accept="image/*" type="file" id="image-input" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HotelPictures;

const Eye = () => (
    <svg viewBox="64 64 896 896" focusable="false" data-icon="eye" width="1em" height="1em" fill="currentColor" aria-hidden="true">
        <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
    </svg>
);
