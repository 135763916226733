import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import { useNavigate, useParams } from "react-router-dom";
import GuestInfo from "../GuestInfo/GuestInfo";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { LoadingButton } from "@mui/lab";
import { Alert } from "antd";
import { FormControlLabel, Switch } from "@mui/material";

const CheckOut = (props) => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [overlay, setOverlay] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSwitch, setIsSwitch] = useState(true);
    const { t } = useTranslation();
    let { id } = useParams();
    if(props?.id){
        id=props?.id
    }
    const handleSwitch = (event) => {
        setIsSwitch(event.target.checked);
        console.log(data)
    };
    const getAxios = async () => {
        
        setIsError(false);
        setLoading(true);
        await axios
            .get(`${ENV.API_URL}/api/hdp/check-in/${id}/details`, {
                headers: { authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
                
            })
            .catch((err) => {
                console.log(err);
                setIsError(true);
                setLoading(false);
            });
    };

    const validate = Yup.object({
        amount: Yup.string().required(t("Field is required")),
    });

    var emptyData = { id, amount: 0 };

    const onSubmit = async (values) => {
        setOverlay(true);
        const guestslength=data.guests.length
        const body = { id: values?.id, credit: values?.amount,values ,guestslength};
        console.log(values)
        await axios
            .post(`${ENV.API_URL}/api/hdp/check-out`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                toast.success(t("Check out successfully"));
                if(props?.handleOpenCheckouttwopage){
                    props.handleOpenCheckouttwopage()
                }
                else{
                    navigate(`/check-out/${id}/pdf`);
                }
                setOverlay(false);
            })
            .catch((err) => {
                setOverlay(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    useEffect(() => {
        getAxios();
    }, []);

    return (
        <div className="container my-5">
            {overlay && <div className="overlay" />}
            {loading ? (
                <Loading />
            ) : isError ? (
                <Alert message={t("Something went wrong")} type="error" />
            ) : (
                <React.Fragment>
                    <h3>{t("Check out")}</h3>
                    <GuestInfo data={data} id={id} />
                    <div>
                        <Formik initialValues={emptyData} validationSchema={validate} onSubmit={onSubmit}>
                            {({ values, errors, isSubmitting }) => (
                                <Form autoComplete="off">
                                    {data.transactions[data.transactions.length - 1].debit === data.transactions[data.transactions.length - 1].credit ? (
                                        <div style={{ color: "#3498db", fontWeight: "500" }}>{t("Payment has been made in full by guest")}</div>
                                    ) : data.transactions[data.transactions.length - 1].debit > data.transactions[data.transactions.length - 1].credit ? (
                                        <div style={{ color: "#e74c3c", fontWeight: "500" }}>{t("Payment has not been made in full by guest")}</div>
                                    ) : data.transactions[data.transactions.length - 1].debit < data.transactions[data.transactions.length - 1].credit ? (
                                        <div style={{ color: "#e74c3c", fontWeight: "500" }}>{t("An overpayment was made by the guest")}</div>
                                    ) : (
                                        ""
                                    )}
                                    <h6 className="mt-1">
                                        {t("Debit")}: {data.transactions[data.transactions.length - 1].debit}
                                    </h6>
                                    <h6>
                                        {t("Credit")}: {data.transactions[data.transactions.length - 1].credit}
                                    </h6>
                                    <FormControlLabel onChange={handleSwitch} control={<Switch defaultChecked />} label={t("send check to guests email")} />
                                    <div>
                                        {isSwitch ?
                                            data.guests.map((guest,index) => {
                                                return < Field style={{ marginRight: "0.5rem" }} required type="email" defaultValue={guest.email} name={`GuestEmail ${index}`} label={`${t('Guest')} (${guest.first_name}) ${t('email')}`} size="small" component={TextField} /> })
                                            :

                                            ""}
                                    </div>
                                    {data.transactions[data.transactions.length - 1].debit !== data.transactions[data.transactions.length - 1].credit && (
                                        <div className="mb-1">
                                            {t("Confirm that you have received rest of amount for check out")} (
                                            {data.transactions[data.transactions.length - 1].debit - data.transactions[data.transactions.length - 1].credit})
                                        </div>
                                    )}
                                    {data.transactions[data.transactions.length - 1].debit !== data.transactions[data.transactions.length - 1].credit && (
                                        <Field style={{ marginRight: "0.5rem" }} type="number" name="amount" size="small" component={TextField} />
                                    )}

                                    {isSubmitting ? (
                                        <LoadingButton loading variant="contained" color="success">
                                            {t("Check out")}
                                        </LoadingButton>
                                    ) : (

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="success"
                                            disabled={
                                                data.transactions[data.transactions.length - 1].debit -
                                                data.transactions[data.transactions.length - 1].credit !==
                                                values.amount &&
                                                data.transactions[data.transactions.length - 1].debit !== data.transactions[data.transactions.length - 1].credit
                                            }
                                        >
                                            {t("Check out")}
                                        </Button>
                                    )}
                                    <div className="right_left">{isSubmitting && <div className="overlay" />}</div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default CheckOut;
