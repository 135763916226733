import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import { green, grey, yellow, blue, lightGreen } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled, useTheme } from "@mui/material/styles";
import { FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Modal, Select, TextField, useMediaQuery } from "@mui/material";
import Loading from "../Loading";
import GuestList from "./guestsList";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import { useNavigate } from "react-router-dom";
import CheckIn from "../CheckIn/Index";
import GuestInfoModal from "../Roomsnew/GuestInfoModal";
import PriceRangeSlider from "./PriceRangeSlider";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--busy": {
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[600],
    },
    "&.Mui-selected": {
      backgroundColor: green[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: green[800],
      },
    },
  },
  "& .super-app-theme--dirty": {
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[600],
    },
    "&.Mui-selected": {
      backgroundColor: grey[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: grey[800],
      },
    },
  },
  "& .super-app-theme--cleaning.in.progress": {
    backgroundColor: yellow[600],
    "&:hover": {
      backgroundColor: yellow[600],
    },
    "&.Mui-selected": {
      backgroundColor: yellow[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: yellow[800],
      },
    },
  },
  "& .super-app-theme--cleaning.complete": {
    backgroundColor: lightGreen[600],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
    "&.Mui-selected": {
      backgroundColor: lightGreen[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: lightGreen[800],
      },
    },
  },
  "& .super-app-theme--in.maintains": {
    backgroundColor: blue[600],
    "&:hover": {
      backgroundColor: blue[600],
    },
    "&.Mui-selected": {
      backgroundColor: blue[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: blue[800],
      },
    },
  },
}));

function DataTable() {
  const [guests, setGuests] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRows, setSelectedRows] = useState([])
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(0);
  const [busy, setBusy] = useState(0);
  const [dirty, setDirty] = useState(0);
  const [clean, setClean] = useState(0);
  const [cleanComplete, setCleanComplete] = useState(0);
  const [maintains, setMaintains] = useState(0);
  const { t } = useTranslation()

  const [openGuestlist, setOpenGuestlist] = useState(false);
  const handleOpenGuestlist = () => setOpenGuestlist(true);
  const handleCloseGuestlist = () => setOpenGuestlist(false);

  const [openCheckin, setOpenCheckin] = useState(false);
  const handleOpenCheckin = () => setOpenCheckin(true);
  const handleCloseCheckin = () => setOpenCheckin(false);
  // const getStatus = async () => {
  //   await axios
  //     .get(`${ENV.API_URL}/api/hdp/room/charts`, {
  //       headers: {
  //         authorization: sessionStorage.getItem("token"),
  //       },
  //     })
  //     .then((res) => {
  //       res.data?.data?.forEach((element) => {
  //           if (element.label === "available") {
  //             setAvailable(element.value);
  //           } else if (element.label === "busy") {
  //             setBusy(element.value);
  //           } else if (element.label === "dirty") {
  //             setDirty(element.value);
  //           } else if (element.label === "cleaning complete") {
  //             setCleanComplete(element.value);
  //           } else if (element.label === "cleaning in progress") {
  //             setClean(element.value);
  //           } else {
  //             setMaintains(element.value);
  //           }
  //         });
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
  //     });
  // };
  const [anchorEl, setAnchorEl] = useState(null);

  // filter state
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [priceRange, setPriceRange] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(Infinity);


  const getAxios = async () => {
    try {
      setLoading(true);

      const token = sessionStorage.getItem("token");

      const categoriesResponse = await axios.get(
        `${ENV.API_URL}/api/hdp/room/category/list`,
        {
          headers: { Authorization: token },
        }
      );
      setCategories(categoriesResponse.data.data);

      const roomsResponse = await axios.get(
        `${ENV.API_URL}/api/hdp/room`,
        {
          headers: { Authorization: token },
        });

      setMinValue(Math.min(...roomsResponse.data.data.map((room) => room.price)))
      setMaxValue(Math.max(...roomsResponse.data.data.map((room) => room.price)))

      setRooms(roomsResponse.data.data);
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.message || t("An error occurred"));
      setLoading(false);
    }
  };

  useEffect(() => {
    getAxios();
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    { field: "number", headerName: t("Room number"), width: 130, align: "center" },
    {
      field: "category_name",
      headerName: t("Category"),
      width: 260,
      align: "left",
    },
    {
      field: "number_of_people",
      headerName: t("Number of people"),
      width: 150,
      align: "center",
    },
    {
      field: "price",
      headerName: t("Price"),
      width: 260,
      align: "center",
    },
    {
      field: "status_name",
      headerName: t("Status"),
      width: 260,
      align: "center",
    },
    {
      field: "action",
      headerName: t("Actions"),
      width: 221,
      align: "right",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon style={{ color: "#fff" }} />
            </IconButton></>
        );
      },
    },
  ];

  const onRowsSelectionHandler = (ids) => {
    setGuests([]);
    setTimeout(() => {
      const selectedRowsData = ids.map((id) =>
        rooms.find((row) => row.id === id)
      );

      setSelectedRows(selectedRowsData);
      setGuests(selectedRowsData[0]?.guests);
    }, 500);
  };

  const [categories, setCategories] = useState([]);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  const currentRooms = rooms
    .filter((room) => {
      if (searchTerm) {
        return room?.number?.toLowerCase().includes(searchTerm?.toLowerCase());
      }
      return true;
    })
    .filter((room) => {
      if (filterStatus) {
        return room.status_name === filterStatus;
      }
      return true;
    })
    .filter((room) => {
      if (filterCategory) {
        return room.category_name === filterCategory;
      }
      return true;
    })
    .filter((room) => {
      return room.price >= priceRange[0] && room.price <= priceRange[1]
    })
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a.number - b.number;
      } else {
        return b.number - a.number;
      }
    });

  const gridItemSize = isXs ? 12 : isSm ? 6 : isMd ? 4 : isLg ? 3 : 3;

  const navigate = useNavigate();

  return (
    <div className="container mt-3">
      <h5>{t("All rooms")}</h5>
      <div>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={gridItemSize}>
            <TextField
              fullWidth
              label={t("Search")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={gridItemSize}>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t("Filter by status")}</InputLabel>
              <Select
                value={filterStatus}
                label={`${t("Filter by status")}`}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <MenuItem value="">{t("All")}</MenuItem>
                <MenuItem value="available">{t("Available")}</MenuItem>
                <MenuItem value="busy">{t("Busy")}</MenuItem>
                <MenuItem value="dirty">{t("Dirty")}</MenuItem>
                <MenuItem value="cleaning in progress">
                  {t("Cleaning in progress")}
                </MenuItem>
                <MenuItem value="in maintains">{t("In maintains")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={gridItemSize}>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t("Filter by category")}</InputLabel>
              <Select
                label={`${t("Filter by category")}`}
                value={filterCategory}
                onChange={(e) => setFilterCategory(e.target.value)}
              >
                <MenuItem value="">{t("All")}</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={gridItemSize}>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t("Sort by number")}</InputLabel>
              <Select
                label={`${t("Sort by number")}`}
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <MenuItem value="asc">{t("Ascending")}</MenuItem>
                <MenuItem value="desc">{t("Descending")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={gridItemSize}>
            <PriceRangeSlider
              label={t("Price range")} min={minValue || 0} max={maxValue || 100} onChange={(newValue) => {
                setPriceRange(newValue);
              }}
            />
          </Grid>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <StyledDataGrid
              {...currentRooms}
              initialState={{
                ...rooms.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25, 100]}
              autoHeight
              rows={currentRooms}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.status_name}`
              }

              onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            />
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '20ch',
                  overflow: 'visible',
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  marginTop: '45px',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem disabled={selectedRows[0]?.status !== 1}
                onClick={handleOpenCheckin}
              >
                {t("Check in")}
              </MenuItem>
              <MenuItem disabled={selectedRows[0]?.status === 1} onClick={() => navigate(`/housekeeping-assignmentv2`)}>{t("Room assignment")}</MenuItem>
              <MenuItem
                onClick={handleOpenGuestlist}
                disabled={selectedRows[0]?.status !== 2}>{t("Guest")}</MenuItem>
              <MenuItem disabled={selectedRows[0]?.status !== 2} onClick={() => navigate(`/check-out/${rooms?.guests[0]?.booking_id}`)} >{t("Check out")}</MenuItem>
            </Menu>

            <Modal
              open={openCheckin}
              onClose={handleCloseCheckin}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropProps={{
                onClick: handleCloseCheckin,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: '8px',
                  maxWidth: '780px',
                  maxHeight: '90%',
                  overflowY: "scroll"
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleCloseCheckin}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <CheckIn />
              </Box>
            </Modal>

            <Modal
              open={openGuestlist}
              onClose={handleCloseGuestlist}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropProps={{
                onClick: handleCloseGuestlist,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: '8px',
                  maxWidth: '780px',
                  width: '100%',
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleCloseGuestlist}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {guests && <GuestInfoModal guestData={guests} />}
              </Box>
            </Modal>
          </Box>
        )}
      </div>
      {guests.length ? (
        <div className="grid_border pb-3 mb-3">
          <GuestList data={guests} />
        </div>
      ) : null}
    </div>
  );
}

export default DataTable;