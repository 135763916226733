import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ENV from "../../../environment";

const RoomCharge = ({ index, guest , showtrue}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [expandedinner, setExpandedinner] = React.useState(false);
  const [roomCharge, setRoomCharge] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const validate = Yup.object({
    debit: Yup.number()
      .required(t("Field is required"))
      .test(
        "Is positive?",
        t("Enter only positive number"),
        (value) => value >= 0
      ),
    credit: Yup.number()
      .required(t("Field is required"))
      .test(
        "Is positive?",
        t("Enter only positive number"),
        (value) => value >= 0
      ),
    comment: Yup.string().required(`${t("Field is required")}`),
  });

  const emptyValue = {
    debit: "",
    credit: "",
    comment: "",
  };

  const handleSubmit = async (event, { resetForm }) => {
    setLoading(true);
    const body = {
      guest_id: guest?.guest_id?.toString(),
      debit: event.debit,
      credit: event.credit,
      comment: event.comment,
    };
    await axios
      .post(`${ENV.API_URL}/api/hdp/room/roomcharges/${guest?.room_id}`, body, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success(t("Saved successfully"));
        resetForm({ values: "" });
        getRoomCharges();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
      });
  };

  const getRoomCharges = async () => {
    setLoading(true);
    await axios
      .get(`${ENV.API_URL}/api/hdp/room/roomcharges/${guest?.guest_id}`, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setRoomCharge(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getRoomCharges();
  }, [expanded]);

  useEffect(() => {
    getRoomCharges();
  }, [showtrue]);

  return (
    <div>
      <Accordion expanded={expanded} className="mb-2">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setExpanded(!expanded)}
        >
          <div>
            {index + 1}. {guest.first_name} {guest.last_name}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {roomCharge?.length ? (
            <>
              <h6>{t("List of room charges:")}</h6>
              {roomCharge?.map((item, index) => {
                return (
                  <p key={item.id}>
                    <span style={{ fontWeight: "bold" }}> {index + 1} : </span>{" "}
                    <span style={{ fontWeight: "bold" }}>{t("Debit")}: </span>
                    {item.debit}{" "}
                    <span style={{ fontWeight: "bold" }}> {t("Credit")}: </span>
                    {item.credit}{" "}
                    <span style={{ fontWeight: "bold" }}> {t("Comment")}: </span>
                    {item?.comments?.length > 10
                      ? item?.comments?.slice(0, 10)
                      : item?.comments}{" "}
                    <span style={{ fontWeight: "bold" }}> {t("Date")}: </span>{" "}
                    {item.transaction_date.slice(0, 10)}
                  </p>
                );
              })}
            </>
          ) : null}
          <Accordion expanded={expandedinner} className="mb-2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExpandedinner(!expandedinner)}
            >
              <Button variant="contained" color="info" size="small">
                {t("Add room charge")}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Formik
                initialValues={emptyValue}
                validationSchema={validate}
                onSubmit={async (values, { resetForm }) => {
                  handleSubmit(values, { resetForm });
                  return new Promise((res) => setTimeout(res, 500));
                }}
              >
                {({ values, errors, resetForm }) => (
                  <Form autoComplete="off">
                    <div className="mb-2">{t("Room charge")}</div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          size="small"
                          fullWidth
                          name="debit"
                          label={t("Debit")}
                          component={TextField}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          size="small"
                          fullWidth
                          name="credit"
                          label={t("Credit")}
                          component={TextField}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          size="small"
                          fullWidth
                          name="comment"
                          label={t("Comment")}
                          component={TextField}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                    <div className="right_left">
                      <Button
                        size="small"
                        onClick={() => setExpandedinner(!expanded)}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="error"
                      >
                        {t("Close")}
                      </Button>
                      <>
                        {loading ? (
                          <LoadingButton
                            loading
                            size="small"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {t("Save")}
                          </LoadingButton>
                        ) : (
                          <Button
                            type="submit"
                            size="small"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {t("Save")}
                          </Button>
                        )}
                      </>
                    </div>
                      {loading && <div className="overlay" />}
                  </Form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RoomCharge;
