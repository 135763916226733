import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import environment from "../../../environment";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const App = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [moredata, setmoredata] = React.useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const getAxios = async () => {
    // setLoading(true);
    console.log(data)
    await axios
      .get(`${environment.API_URL}/api/hdp/guest/${data?.id}`, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setmoredata(res.data.data);
        console.log(moredata)
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        // setLoading(false);
      });
  };

  React.useEffect(() => {
    getAxios();
  }, []);
  return (
    <div className="container">
      <Typography onClick={handleOpen}>{t("Review")}</Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="modal_box">
            <div className="card h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h4 className="mb-2 text-primary">
                      {t("Full info about guest")}
                    </h4>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>{t("First name")}</label>
                      <input
                        type="text"
                        value={data?.first_name}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>{t("Last name")}</label>
                      <input
                        type="text"
                        value={data?.last_name}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  {data.middle_name && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("Middle name")}</label>
                        <input
                          type="text"
                          value={data?.middle_name}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {data?.room_number && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("Room number")}</label>
                        <input
                          type="text"
                          value={data?.room_number}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {data?.document && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("Document number")}</label>
                        <input
                          type="text"
                          value={data?.document}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {data.checked_date && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("Check in date")}</label>
                        <input
                          type="text"
                          value={data?.checked_date?.slice(0, 10)}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {data.checked_date && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("End date")}</label>
                        <input
                          type="text"
                          value={data?.end_date?.slice(0, 10)}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {moredata?.booked && moredata?.booked !== " " && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("Booked by")}</label>
                        <input
                          type="text"
                          value={moredata?.booked}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {moredata?.checked && moredata?.checked !== " " && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("Checked by")}</label>
                        <input
                          type="text"
                          value={moredata?.checked}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {moredata?.checkout && moredata?.checkout !== " " && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>{t("Check out by")}</label>
                        <input
                          type="text"
                          value={moredata?.checkout}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {!data?.total_credit && (<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>{t("Credit")}</label>
                      <input
                        type="text"
                        value={moredata?.total_credit}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>)}
                  {!data?.total_debit && (<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>{t("Debit")}</label>
                      <input
                        type="text"
                        value={moredata?.total_debit}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  )}

                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mt-3 ">
              <Button
                fullWidth
                size="medium"
                variant="contained"
                color="error"
                onClick={handleClose}
                className="btn btn-danger"
              >
                {t("Close")}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
export default App;
