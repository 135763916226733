import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import axios from "axios";
import saveAs from "file-saver";
import Loading from "../Loading";
import { LoadingButton } from "@mui/lab";
import FormControl from "@mui/material/FormControl";
import { FormControls, FormControlWrapper } from "./Style";
import { MenuItem, Select, InputLabel } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "95%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function KeepMountedModal() {
  const { t } = useTranslation();
  const [number, setNumber] = useState(null);
  const [months, setMonths] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [unexpectedfile, setunexpectedfile] = useState(false);
  const [opened, setOpened] = React.useState(false);
  const handleOpened = () => {
    if (number) {
      setOpened(!opened);
    }
  };
  const handleClosed = () => setOpened(!opened);

  const onChange = async (e) => {
    setNumber(e.target.value);
  };

  const getMonths = async () => {
    await axios
      .get(`${ENV.API_URL}/api/hdp/booking/checkallmonthly`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.data)
        setMonths([...res.data.data, { id: 189, files_uuid: '', month: 23, hotel_id: 4 }]);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  const getPdf = async () => {
    setLoadingBtn(true);
    console.log(number)
    if (number == 23) {
      
      await axios
        .get(
          `${ENV.API_URL}/api/hdp/booking/checkCurrentmonthly/`,
          {
            responseType: "blob",
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const reader = new FileReader();
          let file = res.data;
          reader.onload = (event) => {
            window.document.getElementById("a").href = `${event.target.result}`;
            saveAs(file);
          };
          reader.readAsDataURL(file);
          setLoadingBtn(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
          setLoadingBtn(false);
        });
    
    }
    else {
      await axios
        .get(
          `${ENV.API_URL}/api/hdp/booking/Checkmonthly/${number}`,
          {
            responseType: "blob",
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const reader = new FileReader();
          let file = res.data;
          reader.onload = (event) => {
            window.document.getElementById("a").href = `${event.target.result}`;
            saveAs(file);
          };
          reader.readAsDataURL(file);
          setLoadingBtn(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
          setLoadingBtn(false);
        });
    }
  };

  const getFile = () => {
    if (number) {
      setloading(true);
      setError(false);
      setunexpectedfile(false);
      if(number==23){
        axios
        .get(
          `${ENV.API_URL}/api/hdp/booking/checkCurrentmonthly/?lan=${localStorage.getItem('i18nextLng')}`,
          {
            responseType: "blob",
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          setError(false);
          setloading(false);
          const reader = new FileReader();
          reader.onload = (event) => {
            if (
              response.data.type === "application/pdf" ||
              /image/.test(response.data.type)
            ) {
              setunexpectedfile(false);
              window.document.getElementById(
                "pdf"
              ).src = `${event.target.result}`;
            } else {
              setunexpectedfile(true);
              saveAs(response.data);
            }
          };
          reader.readAsDataURL(response.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
          setError(true);
          setloading(false);
        });
      }
      else{axios
        .get(
          `${ENV.API_URL}/api/hdp/booking/Checkmonthly/${number}`,
          {
            responseType: "blob",
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          setError(false);
          setloading(false);
          const reader = new FileReader();
          reader.onload = (event) => {
            if (
              response.data.type === "application/pdf" ||
              /image/.test(response.data.type)
            ) {
              setunexpectedfile(false);
              window.document.getElementById(
                "pdf"
              ).src = `${event.target.result}`;
            } else {
              setunexpectedfile(true);
              saveAs(response.data);
            }
          };
          reader.readAsDataURL(response.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
          setError(true);
          setloading(false);
        });}
    } else {
      toast.warning(t("Select month"));
    }
  };

  useEffect(() => {
    getMonths();
  }, []);
  return (
    <div>
      <FormControlWrapper>
        <FormControls>
          <FormControl style={{ width: "200px" }}>
            <InputLabel id="demo-simple-select-label">
              {t("Select month")}
            </InputLabel>
            <Select
              placeholder="Select month"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Month"
              onChange={onChange}
              fullWidth
            >
              {months.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.month}>
                    {value.month === 1 ? t("January") : null}
                    {value.month === 2 ? t("February") : null}
                    {value.month === 3 ? t("March") : null}
                    {value.month === 4 ? t("April") : null}
                    {value.month === 5 ? t("May") : null}
                    {value.month === 6 ? t("June") : null}
                    {value.month === 7 ? t("July") : null}
                    {value.month === 8 ? t("August") : null}
                    {value.month === 9 ? t("September") : null}
                    {value.month === 10 ? t("October") : null}
                    {value.month === 11 ? t("November") : null}
                    {value.month === 12 ? t("December") : null}
                    {value.month === 23 ? t("Current month") : null}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </FormControls>
        <Button
          variant="contained"
          onClick={() => {
            handleOpened();
            getFile();
          }}
        >
          {t("View PDF")}
        </Button>
      </FormControlWrapper>
      <>
        <Modal
          keepMounted
          open={opened}
          onClose={handleClosed}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="modal_box"
            sx={style}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {unexpectedfile && (
              <h2 className="title_download">File downloaded</h2>
            )}
            {error && (
              <h2 className="title_download">{t("An error occurred")}</h2>
            )}
            {loading && <Loading />}
            {!loading && !unexpectedfile && !error && (
              <iframe
                style={{
                  height: "calc(100% - 30px)",
                  minHeight: "85vh",
                  minWidth: "100%",
                }}
                id="pdf"
              ></iframe>
            )}
            {!loading && (
              <div className="d-flex justify-content-between align-items-center w-100 mt-3 ">
                <Button
                  size="medium"
                  variant="contained"
                  color="error"
                  onClick={handleClosed}
                  className="btn btn-danger"
                >
                  {t("Close")}
                </Button>
                <>
                  <a href="" id="a" />
                  {loadingBtn ? (
                    <LoadingButton size="medium" loading variant="contained">
                      {" "}
                      {t("Generate Pdf")}
                    </LoadingButton>
                  ) : (
                    <Button
                      size="medium"
                      color="success"
                      variant="contained"
                      onClick={getPdf}
                    >
                      {t("download Pdf")}
                    </Button>
                  )}
                </>
              </div>
            )}
          </Box>
        </Modal>
      </>
    </div>
  );
}
