import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import environment from "../../environment";

export default function ChangeStatus({ id, data, getData, closeMenu }) {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
      console.log(open);
  };

  const toMaintain = async () => {
    setLoading(true);
    const body = {
      room_id: id,
      status: 5,
    };
    await axios
      .patch(
        `${environment.API_URL}/api/hdp/user/housekeeper/update-room-status`,
        body,
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      )
      .then((res) => {
        toast.success(t("Room status changed to in maintain"));
        handleClose();
        getData();
        setLoading(false);
        closeMenu()
    })
    .catch((err) => {
        setLoading(false);
        toast.error(
            err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
    });
};

const toAvailable = async () => {
    setLoading(true);
    const body = {
        room_id: id,
        status: 1,
    };
    await axios
    .patch(
        `${environment.API_URL}/api/hdp/user/housekeeper/update-room-status`,
        body,
        {
            headers: { Authorization: sessionStorage.getItem("token") },
        }
    )
    .then((res) => {
        toast.success(t("Room status changed to available"));
        handleClose();
        getData();
        setLoading(false);
        closeMenu()
    })
    .catch((err) => {
        setLoading(false);
        toast.error(
            err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
      });
  };

  const onChangeStatus = () => {
    if (data?.status === 1) {
      toMaintain();
    } else {
      toAvailable();
    }
  };
  return (
    <React.Fragment>
      <Typography onClick={handleClickOpen}>
        {t(
          data?.status !== 5
            ? t("Change status to in maintain")
            : t("Change status to available")
        )}
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Attention!")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data?.status!==5 ? t("Are you sure you want to change the room state to in maintain?") : t("Are you sure you want to change the room state to available?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <div className="right_left">
          <Button onClick={handleClose}>{t("Disagree")}</Button>
          <Button onClick={onChangeStatus} autoFocus>
            {t("Agree")}
          </Button>
        </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
