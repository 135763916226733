import React from "react";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import axios from "axios";
import environment from "../../environment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";

const Home = () => {
  const { t } = useTranslation();
  const [pieData, setPieData] = React.useState([]);
  const [barData, setBarData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getPieData = async () => {
    await axios(`${environment.API_URL}/api/hdp/room/charts`, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => {
        console.log(res.data.data);
        setPieData(res.data.data);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
      });
  };
  const getBarData = async () => {
    await axios(`${environment.API_URL}/api/hdp/room/dashboard`, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => {
        console.log(res.data.data);
        for (let i = 0; i < res.data.data.length; i++) {
          const element = res.data.data[i];
          if (element.month === 0) element.month = t("January");
          else if (element.month === 1) element.month = t("February");
          else if (element.month === 2) element.month = t("March");
          else if (element.month === 3) element.month = t("April");
          else if (element.month === 4) element.month = t("May");
          else if (element.month === 5) element.month = t("June");
          else if (element.month === 6) element.month = t("July");
          else if (element.month === 7) element.month = t("August");
          else if (element.month === 8) element.month = t("September");
          else if (element.month === 9) element.month = t("October");
          else if (element.month === 10) element.month = t("November");
          else if (element.month === 11) element.month = t("December");
        }
        setBarData(res.data.data);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
      });
  };

  const getAllData = async () => {
    setLoading(true);
    await getPieData();
    await getBarData();
    setLoading(false);
  };
  React.useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <div className="home_bars">
        {loading ? (
          <Loading />
        ) : (
          <div className="home_bars_wrapper">
              <PieChart data={pieData} />
              <BarChart data={barData} />
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
