import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import { useNavigate } from "react-router-dom";

const CheckOutTable = () => {  
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const {t} = useTranslation()
  const onSearch = async (e) => {
    if (e?.length) {
      setLoading(true);
      setOverlay(true);
      await axios
        .get(
          `${ENV.API_URL}/api/hdp/check-out/search?query=${e}`,
          {
            headers: { authorization: sessionStorage.getItem("token") },
          }
        )
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
          setOverlay(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
          setLoading(false);
          setOverlay(false);
        });
    } else {
      getAxios();
    }
  };

  const onCheckOut = (id) => {
    navigate(`/check-out/${id}`)
  }


  const columns = [
    {
      field: "number",
      headerName: t("Room number"),
      width: 230,
    },
    {
      field: "category_name",
      headerName: t("Category"),
      width: 230,
    },
    {
      field: "debits",
      headerName: t("Debit"),
      width: 180,
    },
    {
      field: "credits",
      headerName: t("Credit"),
      width: 180,
    },
    {
      field: "status_name",
      headerName: t("Status"),
      width: 230,
    },
    {
      field: "action",
      type: 'actions',
      headerName: t("Action"),
      width: 230,
      renderCell: (data) => {
        return <Button variant="outlined" onClick={()=>onCheckOut(data.id)}>{t("Check out")}</Button>;
      },
    },
  ];
  
  const getAxios = async () => {
    setLoading(true);
    await axios
      .get(`${ENV.API_URL}/api/hdp/check-out/search`, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        setLoading(false);
      });
  };
  
  useEffect(() => {
    getAxios();
  }, []);
  return (
    <div className="container mt-5">
      {overlay && <div className="overlay" />}
      <div className="d-flex justify-content-between align-items-center">
        <h5>{t("Check out")}</h5>
        <input
          type="number"
          placeholder={t("Room number")}
          className="form-control  w-75"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="mt-5">
          <DataGrid
            {...data}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25, 100]}
            autoHeight
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableRowSelectionOnClick
          />
        </div>
      )}
    </div>
  );
};

export default CheckOutTable;