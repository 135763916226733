import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import tts from '../../assets/favicon.ico'

const Footer = () => {
  const {t} = useTranslation()
  const date = new Date().getFullYear();
  return (
    <footer style={{height:"10vh"}} className="text-center text-lg-start bg-body-tertiary text-muted border">
      <section className="d-flex justify-content-center justify-content-lg-between p-4  footer_all_right">
        <div>
          <h6 className="text-primary"><img src={tts} alt="Logo"  style={{width:"40px"}} />{t("All Rights Reserved.")}</h6>
        </div>
        <div>
          <h6 className="text-primary">© {date} {t("Copyright")}: <Link className="Copyright_link text-reset fw-bold" to="https://www.transoxania.uz">transoxania.uz</Link></h6>
        </div>
      </section>
    </footer>
  );
};

export default Footer;