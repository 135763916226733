import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Box } from "@mui/material";
import ShaxmatkaCheckIn from "../Shaxmatka/CheckIn/CheckIn";
import CheckIn from "../CheckIn/Index";
import { useNavigate } from "react-router-dom";
import GuestInfoModal from "./GuestInfoModal";
import { useTranslation } from "react-i18next";
import CheckOutPage from "../../components/CheckOut/CheckOut";
import CheckOutPdfPage from "../../components/CheckOut/PDF";
function capitalizeFirstLetter(text) {
    if (!text) return text; // In case the input is empty or null
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
const CardContainer = styled("div")(({ status }) => ({
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: (() => {
        switch (status) {
            case "available":
                return "#e0e0e0";
            case "busy":
                return "#07bc0c";
            case "dirty":
                return "#757575";
            case "cleaning in progress":
                return "#f1c40f";
            case "in maintains":
                return "#0dcaf0";
            default:
                return "#ffffff";
        }
    })(),
    textAlign: "center",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
    position: "relative",
}));

const Card = ({ data, categories, fetchData }) => {
    const navigate = useNavigate();

    const [openCheckin, setOpenCheckin] = useState(false);
    const handleOpenCheckin = () => setOpenCheckin(true);
    const handleCloseCheckin = () => setOpenCheckin(false);

    const [openGuestlist, setOpenGuestlist] = useState(false);
    const handleOpenGuestlist = () => setOpenGuestlist(true);
    const handleCloseGuestlist = () => setOpenGuestlist(false);

    const [openCheckout, setOpenCheckout] = useState(false);
    const handleOpenCheckout = () => setOpenCheckout(true);
    const handleCloseCheckout = () => setOpenCheckout(false);

    const [openCheckouttwopage, setOpenCheckouttwopage] = useState(false);
    const handleOpenCheckouttwopage = () => {
        handleCloseCheckout()
        setOpenCheckouttwopage(true)
    };
    const handleCloseCheckouttwopage = () => {
        getRoomdata()
        setOpenCheckouttwopage(false)
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const getRoomdata = async () => {
        handleClose()
        handleCloseCheckin()
        handleCloseGuestlist()
        await fetchData()
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { t } = useTranslation();

    return (
        <CardContainer status={data.status_name}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                <p style={{ display: 'none' }}>{data.id}</p>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon style={{ color: "#fff" }} />
                </IconButton>
            </div>
            <p>{data?.number}</p>
            <p>{data?.category_name}</p>
            <p>{`${t(capitalizeFirstLetter(data?.status_name.toString()))}`}</p>
            <p>{`${t("Number of people")}: ${data?.number_of_people}`}</p>
            <p>{`${t("Price")}: ${data?.price}`}</p>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                        overflow: 'visible',
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                        borderRadius: '8px',
                        marginTop: '45px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem disabled={data.status !== 1} onClick={handleOpenCheckin}>
                    {t("Check in")}
                </MenuItem>
                <MenuItem disabled={data.status === 1} onClick={() => navigate(`/housekeeping-assignmentv2`)}>{t("Room assignment")}</MenuItem>
                <MenuItem onClick={handleOpenGuestlist} disabled={data.status !== 2}>{t("Guest")}</MenuItem>
                <MenuItem disabled={data.status !== 2} onClick={() => handleOpenCheckout()} >{t("Check out")}</MenuItem>
            </Menu>

            <Modal
                open={openCheckin}
                onClose={handleCloseCheckin}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleCloseCheckin,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        maxWidth: '780px',
                        maxHeight: '90%',
                        overflowY: "scroll"
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseCheckin}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <CheckIn getRoomdata={getRoomdata} />
                </Box>
            </Modal>

            <Modal
                open={openGuestlist}
                onClose={handleCloseGuestlist}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleCloseGuestlist,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        maxWidth: '780px',
                        width: '100%',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseGuestlist}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <GuestInfoModal guestData={data.guests} />
                </Box>
            </Modal>

            <Modal
                open={openCheckout}
                onClose={handleCloseCheckout}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleCloseCheckout,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        width: '70vw',
                        maxHeight: '90%',
                        overflowY: "scroll"
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseCheckout}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <CheckOutPage id={data?.guests[0]?.booking_id} handleOpenCheckouttwopage={handleOpenCheckouttwopage} />
                </Box>
            </Modal>



            <Modal
                open={openCheckouttwopage}
                onClose={handleCloseCheckouttwopage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleCloseCheckouttwopage,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        width: '70vw',
                        maxHeight: '90%',
                        overflowY: "scroll"
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseCheckouttwopage}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <CheckOutPdfPage id={data?.guests[0]?.booking_id} handleOpenCheckouttwopage={handleOpenCheckouttwopage} />
                </Box>
            </Modal>


        </CardContainer>
    );
};

export default Card;
