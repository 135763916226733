import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormControls, FormControlWrapper, ListBtn } from "./ListStyle";
import { InputLabel, Select, MenuItem, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import { createGet } from "../../Context/GetRolesContext";
import ENV from "../../../environment";

const List = () => {
  const {t} = useTranslation()
  const [get,setGet] = useContext(createGet)
  const [overlay, setOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirtyRooms, setDirtyRooms] = useState([]);
  const [data, setData] = useState([]);
  const [housekeepers, setHousekeepers] = useState([]);
  const [housekeeper_id, setHousekeeper_id] = useState("");
  const [assignRooms, setAssignRooms] = useState([]);
  const [boards, setBoards] = useState([
    {
      id: 1,
      title: t("Dirty rooms"),
      items: [],
    },
  ]);
  const [boards2, setBoards2] = useState([
    {
      id: 1,
      title: t("Housekeeper's rooms"),
      items: [],
    },
  ]);
  const [currentBoard, setCurrentBoard] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const dragOverHandler = (e) => {
    e.preventDefault();
  };
  const dragLeaveHandler = (e) => {
    e.target.style.boxShadow = "none";
  };
  const dragStartHandler = (e, board, item) => {
    setCurrentBoard(board);
    setCurrentItem(item);
  };
  const DropHandler = (e, board, item) => {
    e.preventDefault();
    const currentIndex = currentBoard.indexOf(currentItem);
    currentBoard.splice(currentIndex, 1);
    setAssignRooms([...assignRooms, currentItem]);
    setBoards2(
      boards.map((b) => {
        if (b.id === board.id) {
          return board;
        }
        if (b.id === currentBoard.id) {
          return currentBoard;
        }
        return b;
      })
    );
  };
  const DropHandler2 = (e, board, item) => {
    e.preventDefault();
    const currentIndex = currentBoard.indexOf(currentItem);
    currentBoard.splice(currentIndex, 1);
    setDirtyRooms([...dirtyRooms, currentItem]);
    setBoards(
      boards.map((b) => {
        if (b.id === board.id) {
          return board;
        }
        if (b.id === currentBoard.id) {
          return currentBoard;
        }
        return b;
      })
    );
  };
  const dragEndHandler = (e) => {
    e.target.style.boxShadow = "none";
  };
  const getAllDirtyRooms = async () => {
    setOverlay(true);
    await axios
      .get(`${ENV.API_URL}/api/hdp/user/for-assignment/3`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setData(response.data.data);
        setDirtyRooms(response.data.data);
        setOverlay(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  function getHousekeepers() {
    axios
      .get(`${ENV.API_URL}/api/hdp/user/housekeepers`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setHousekeepers(response.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  }

  const handleChange = async (e) => {
    setOverlay(true);

    setHousekeeper_id(e.target.value);
    await axios
      .get(
        `${ENV.API_URL}/api/hdp/user/housekeeper/${e.target.value.id}/status/3`,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setDirtyRooms(
          data.filter(
            (value) =>
              response.data.data
                .map((e) => +e.number)
                .indexOf(+value.number) === -1
          )
        );
        setAssignRooms(response.data.data);
        setOverlay(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        setOverlay(false);
      });
  };

  const handleChangeFilter = async (event) => {
    setOverlay(true);
    setHousekeeper_id("");
    setAssignRooms([]);
    await axios
      .get(
        `${ENV.API_URL}/api/hdp/user/for-assignment/${event.target.value}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setDirtyRooms(response.data.data);
        setOverlay(false);
        response?.data?.data?.forEach((element) => {
          if (element.status_name === "busy") {
            setBoards([
              {
                id: 1,
                title: t("Busy rooms"),
                items: [],
              },
            ]);
          } else if (element.status_name === "dirty") {
            setBoards([
              {
                id: 1,
                title: t("Dirty rooms"),
                items: [],
              },
            ]);
          }
        });
      })
      .catch((err) => {
        setOverlay(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  const onSave = () => {
    setLoading(true);
    const data = {
      staff_id: housekeeper_id.id,
      rooms: [],
    };
    for (let i = 0; i < assignRooms.length; i++) {
      data.rooms.push(assignRooms[i].id);
    }
    axios
      .patch(
        `${ENV.API_URL}/api/hdp/user/room_assignment`,
        data,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        toast.success(t("Saved successfully"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  useEffect(() => {
    getAllDirtyRooms();
    getHousekeepers();
  }, []);
  useEffect(() => {
    setBoards([
  {
    id: 1,
    title: t("Dirty rooms"),
    items: [],
  },
    ]);
    setBoards2([
  {
    id: 1,
    title: t("Housekeeper's rooms"),
    items: [],
  },
    ]);
  }, [!get]);

  return (
    <div className="container">
      <FormControlWrapper>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">{t("Filter")}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={3}
            name="radio-buttons-group"
            onChange={handleChangeFilter}
          >
            <div className="d-flex">
              <FormControlLabel
                value={3}
                control={<Radio />}
                label={t("Dirty")}
                defaultChecked
              />
              <FormControlLabel value={2} control={<Radio />} label={t("Busy")} />
            </div>
          </RadioGroup>
        </FormControl>
        <FormControls>
          <FormControl style={{ width: "200px" }}>
            <InputLabel id="demo-simple-select-label">
              {t("Select Housekeeper")}
            </InputLabel>
            <Select
              placeholder="Select User"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={housekeeper_id}
              label="Select User"
              onChange={handleChange}
              fullWidth
            >
              {housekeepers.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value.first_name} {value.last_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </FormControls>
      </FormControlWrapper>
      {overlay && <div className="overlay" />}
      <div className="DropContainer">
        {boards.map((value) => {
          return (
            <div
              className="Board"
              key={value.id}
              onDragOver={(e) => dragOverHandler(e)}
              onDrop={(e) => DropHandler2(e, dirtyRooms, 1)}
            >
              <div className="Board_title" key={value.id}>
                {value.title}
              </div>
              {dirtyRooms.map((value) => {
                return (
                  <div
                    className="drop_item"
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragStart={(e) => dragStartHandler(e, dirtyRooms, value)}
                    onDragEnd={(e) => dragEndHandler(e)}
                    draggable={true}
                    key={value.id}
                  >
                    Room number : {value.number}
                  </div>
                );
              })}
            </div>
          );
        })}
        {boards2.map((value) => {
          return (
            <div
              className="Board"
              key={value.id}
              onDragOver={(e) => dragOverHandler(e)}
              onDrop={(e) => DropHandler(e, value, 1)}
            >
              <div className="Board_title" key={value.id}>
                {value.title}
              </div>
              {assignRooms.map((prod) => {
                return (
                  <div
                    className="drop_item"
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragStart={(e) => dragStartHandler(e, assignRooms, prod)}
                    onDragEnd={(e) => dragEndHandler(e)}
                    draggable={true}
                    key={prod.id}
                  >
                    Room number : {prod.number}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <ListBtn>
        {loading ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
           {t("Save")}
          </LoadingButton>
        ) : (
          <Button
            onClick={onSave}
            variant="contained"
            color="success"
            disabled={housekeeper_id ? false : true}
          >
           {t("Save")}
          </Button>
        )}
      </ListBtn>
    </div>
  );
};

export default List;
