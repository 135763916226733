import { Box, Grid, Slider, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";

const PriceRangeSlider = ({ label, min, max, onChange }) => {

    const [value, setValue] = useState([min, max]);
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    const handleChange = (_, newValue) => {
        setValue(newValue);
        setMinValue(newValue[0]);
        setMaxValue(newValue[1]);
    };

    const handleMinChange = (event) => {
        const newMinValue = Number(event.target.value);

        if (newMinValue >= value[0] && newMinValue <= value[1]) {
            setMinValue(newMinValue);
            setValue([newMinValue, value[1]]);
        }
    };

    const handleMaxChange = (event) => {
        const newMaxValue = Number(event.target.value);

        if (newMaxValue >= value[0] && newMaxValue <= max) {
            setMaxValue(newMaxValue);
            setValue([value[0], newMaxValue]);
        }
    };

    useEffect(() => {
        onChange(value)
    }, [minValue, maxValue]);

    useEffect(() => {
        setValue([min, max])
        onChange(value)
    }, [min, max]);

    return (
        <Box sx={{ width: "100%", value }}>
            <Typography gutterBottom>{label}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label={t("Min")}
                        type="number"
                        value={minValue || min}
                        onChange={handleMinChange}
                        fullWidth
                        InputProps={{ readOnly: true, inputProps: { min: 0, max: value[1] } }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t("Max")}
                        type="number"
                        value={maxValue || max}
                        onChange={handleMaxChange}
                        fullWidth
                        InputProps={{ readOnly: true, inputProps: { min: value[0], max } }}
                    />
                </Grid>
            </Grid>
            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={min}
                max={max}
                sx={{ mb: 2 }}
            />
        </Box>
    );
};

export default PriceRangeSlider;