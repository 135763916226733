import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import BookingTable from "./BookingTable.jsx";
import Calendar from "./Calendar/Index.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}><Typography>{children}</Typography></Box> }
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const {t} = useTranslation()
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event, newValue) => setValue(newValue);

  const getData = async () => {
    await axios.get(`${ENV.API_URL}/api/hdp/booking`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      }).then((res) => { 
        setData(res.data.data);
        res.data.data?.forEach(el => {
          el.start = new Date(el.start_date)
          el.end = new Date(el.end_date)
          el.title = <p id="c_title" className={`${(el.type === 1 && 'green') || (el.type === 2 && 'purple') || (el.type === 3 && 'blue') || (el.type === 4 && 'yellow') || (el.type === 5 && 'orange')}`}>{el.room_number}-xona 
              <span id="f_name" className={`${(el.type === 1 && 'green') || (el.type === 2 && 'purple') || (el.type === 3 && 'blue') || (el.type === 4 && 'yellow') || (el.type === 5 && 'orange')}`}>{el.first_name?.slice(0, 1)+"."+el.last_name}</span>
            </p>
      })
      setEvents(res.data.data);
      }).catch((err) => setError(true));
  };

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="tabs_box my-4">
      <div className="center_between my-3"><h5>{t("Booking")}</h5></div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs" orientation={isSmallScreen ? "vertical" : "horizontal"}>
            <Tab label={t("Calendar")} {...a11yProps(0)} />
            <Tab label={t("Table")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}><Calendar events={events} setEvents={setEvents}/></TabPanel>
        <TabPanel value={value} index={1}><BookingTable  getData={getData} error={error} dataa={data} /></TabPanel>
      </Box>
    </div>
  );
}

export default React.memo(BasicTabs);