import React from "react";
import { Flex, Spin } from "antd";
const App = () => (
  <Flex gap="small" vertical style={{ marginTop: "50px" }}>
    <Spin tip="Loading..." size="large">
      {/* <div className="content" /> */}
      
    </Spin>
  </Flex>
);
export default App;
