import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import Loading from "../Loading";
import axios from "axios";
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import "./style.css";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const HotelLogo = () => {
    const { t } = useTranslation();
    const [src, setSrc] = useState(null);
    const [exist, setExist] = useState(null);
    const [loading, setLoading] = useState(true);

    async function getLogo() {
        setLoading(true);
        await fetch(`${REACT_APP.API_URL}/api/hdp/hotel/logo`, {
            headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.CLIENTSECRET },
            method: "GET",
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type === "application/json") setExist(false);
                else {
                    setExist(true);
                    console.log(window.URL.createObjectURL(blob));
                    setSrc(window.URL.createObjectURL(blob));
                }
                setLoading(false);
                console.log(blob.type);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
                console.log(err);
                setLoading(false);
            });
    }
    useEffect(() => {
        getLogo();
    }, []);

    const [file, setFile] = useState("");
    const [uploading, setUploading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const closeRef = useRef(null);

    const changeFile = (e) => setFile(e.target.files[0]);

    const submit = async (date) => {
        setUploading(true);
        const data = new FormData();
        data.append("file", file);
        axios
            .put(`${REACT_APP.API_URL}/api/hdp/hotel/logo`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setUploading(false);
                closeRef.current.click();
                getLogo();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setUploading(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <div className="container mt-3">
            {uploading && <div className="overlay"></div>}
            <h3>{t("Hotel logo")}</h3>
            <Button className="float-end" variant="contained" color="primary" onClick={handleOpen}>
                {exist ? t("Update logo") : t("Add logo")}
            </Button>
            <Modal open={open} onClose={handleClose} closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
                <Box sx={style} className="modal_res">
                    <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                        <span htmlFor="file">{t("Choose logo to upload")}</span>
                        <Grid item container spacing={2} xs={12}>
                            <Grid item xs={12} sm={4}>
                                <Button type="submit" variant="contained" disabled={uploading}>
                                    {t("Choose")}
                                    <input disabled={uploading} type="file" id="hli-file" name="file" accept="image/*" onChange={changeFile} />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={2}></Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="li-div">{!!file && <img alt="logo" src={window.URL.createObjectURL(file)} id="l-image" height={300} />}</div>
                            </Grid>
                        </Grid>
                        <div className="l-page">
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" className="float-end" disabled={!file || uploading} onClick={submit}>
                                    {uploading && <CircularProgress size="0.9rem" />}
                                    {t("Upload")}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="button" variant="contained" className="float-start" color="error" ref={closeRef} onClick={handleClose}>
                                    {t("Close")}
                                </Button>
                            </Grid>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            {loading ? <Loading /> : <div className="hotel-logo-image">{exist && <img src={src} alt="logo" width="100%" />}</div>}
        </div>
    );
};

export default HotelLogo;
