import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import axios from "axios";
import saveAs from "file-saver";
import Loading from "../Loading";
import { LoadingButton } from "@mui/lab";
import FormControl from "@mui/material/FormControl";
import { FormControls, FormControlWrapper } from "./Style";
import { MenuItem, Select, InputLabel } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";

const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function KeepMountedModal() {
    const { t } = useTranslation();
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [months, setMonths] = useState([]);
    const [cycle, setcycle] = useState("oylik");
    const [waiting, setWaiting] = useState();

    const [error, setError] = useState(false);
    const [loading, setloading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [unexpectedfile, setunexpectedfile] = useState(false);
    const [opened, setOpened] = React.useState(false);
    const handleOpened = () => {
        if (selectedMonth) {
            setOpened(!opened);
        }
    };
    const handleClosed = () => setOpened(!opened);

    const getMonths = async () => {
        setWaiting(true);
        await axios
            .get(`${ENV.API_URL}/api/hdp/booking/check/booking/${cycle}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setWaiting(false);
                setMonths(res.data.data);
            })
            .catch((err) => {
                setWaiting(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    const onChange1 = async (e) => {
        setcycle(e.target.value);
    };

    useEffect(() => {
        setSelectedMonth(null);
        getMonths();
    }, [cycle]);

    const onChange2 = async (e) => {
        setSelectedMonth(e.target.value);
    };

    const getPdf = async () => {
        setLoadingBtn(true);
        await axios
            .get(`${ENV.API_URL}/api/hdp/booking/check/booking/doc/${selectedMonth}`, {
                responseType: "blob",
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                const reader = new FileReader();
                let file = res.data;
                reader.onload = (event) => {
                    window.document.getElementById("a").href = `${event.target.result}`;
                    saveAs(file);
                };
                reader.readAsDataURL(file);
                setLoadingBtn(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
                setLoadingBtn(false);
            });
    };

    const getFile = () => {
        setloading(true);
        setError(false);
        setunexpectedfile(false);
        axios
            .get(`${ENV.API_URL}/api/hdp/booking/check/booking/doc/${selectedMonth}`, {
                responseType: "blob",
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((response) => {
                setError(false);
                setloading(false);
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (response.data.type === "application/pdf" || /image/.test(response.data.type)) {
                        setunexpectedfile(false);
                        window.document.getElementById("pdf").src = `${event.target.result}`;
                    } else {
                        setunexpectedfile(true);
                        saveAs(response.data);
                    }
                };
                reader.readAsDataURL(response.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
                setError(true);
                setloading(false);
            });
    };

    return (
        <div>
            <FormControlWrapper>
                <FormControls>
                    <FormControl style={{ width: "200px" }}>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={onChange1} defaultValue={cycle} fullWidth>
                            <MenuItem value={"oylik"}>{t("Oylik")}</MenuItem>
                            <MenuItem value={"yarim oylik"}>{t("Yarim oylik")}</MenuItem>
                        </Select>
                    </FormControl>
                </FormControls>
                <FormControls>
                    <FormControl style={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">{t("Tanlang")}</InputLabel>
                        <Select
                            placeholder={t("Tanlang")}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("Tanlang")}
                            onChange={onChange2}
                            fullWidth
                            disabled={waiting}
                            value={selectedMonth}
                        >
                            {months.map((el, index) => (
                                <MenuItem value={el.name} key={index}>
                                    {el.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormControls>
                <Button
                    variant="contained"
                    disabled={!selectedMonth}
                    onClick={() => {
                        handleOpened();
                        getFile();
                    }}
                >
                    {t("View PDF")}
                </Button>
            </FormControlWrapper>
            <>
                <Modal
                    keepMounted
                    open={opened}
                    onClose={handleClosed}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box className="modal_box" sx={style} style={{ display: "flex", flexDirection: "column" }}>
                        {unexpectedfile && <h2 className="title_download">File downloaded</h2>}
                        {error && <h2 className="title_download">{t("An error occurred")}</h2>}
                        {loading && <Loading />}
                        {!loading && !unexpectedfile && !error && (
                            <iframe
                                style={{
                                    height: "calc(100% - 30px)",
                                    minHeight: "85vh",
                                    minWidth: "100%",
                                }}
                                id="pdf"
                            ></iframe>
                        )}
                        {!loading && (
                            <div className="d-flex justify-content-between align-items-center w-100 mt-3 ">
                                <Button size="medium" variant="contained" color="error" onClick={handleClosed} className="btn btn-danger">
                                    {t("Close")}
                                </Button>
                                <>
                                    <a href="" id="a" />
                                    {loadingBtn ? (
                                        <LoadingButton size="medium" loading variant="contained">
                                            {t("Generate Pdf")}
                                        </LoadingButton>
                                    ) : (
                                        <Button size="medium" color="success" variant="contained" onClick={getPdf}>
                                            {t("download Pdf")}
                                        </Button>
                                    )}
                                </>
                            </div>
                        )}
                    </Box>
                </Modal>
            </>
        </div>
    );
}
