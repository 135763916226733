const NODE_ENV = process.env.REACT_APP_ENV || "production";

const REACT_APP = {
    development: {
        API_URL: "http://142.93.128.235:8080",
        // API_URL: "http://localhost:8080",
        CLIENTID: "36f7274bb5f75fcd4b37037ab1b4e36024292cfc4b68babc596e57bc803f6a4c",
        CLIENTSECRET: "7MV-Qr}W5,YR<5&&4^nA/U8'?Blh<b`l&?C,BTo({(-9vI-}_dezJczH9&N?4fc;",

        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
    },
    production: {
        API_URL: "https://api.karvonsaroyi.uz",
        CLIENTID: "36f7274bb5f75fcd4b37037ab1b4e36024292cfc4b68babc596e57bc803f6a4c",
        CLIENTSECRET: "7MV-Qr}W5,YR<5&&4^nA/U8'?Blh<b`l&?C,BTo({(-9vI-}_dezJczH9&N?4fc;",
        
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
    },
};

export default REACT_APP[NODE_ENV];
