import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "./card";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";

function DataTable() {
  const [rooms, setRooms] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [roomsPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { t } = useTranslation();

  // Use theme and media query to determine grid item size
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  // Calculate grid item size based on screen size
  const gridItemSize = isXs
    ? 12
    : isSm
    ? 6
    : isMd
    ? 4
    : isLg
    ? 3
    : 3; // Default to 3 for larger screens

  const fetchData = async () => {
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");

      const categoriesResponse = await axios.get(
        `${ENV.API_URL}/api/hdp/room/category/list`,
        {
          headers: { Authorization: token },
        }
      );
      setCategories(categoriesResponse.data.data);

      const currentDate = new Date().toJSON().slice(0, 10);
      const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000)
        .toJSON()
        .slice(0, 10);
      const reservationQuery = `?start_date=${currentDate}&end_date=${tomorrow}`;
      const reservationsResponse = await axios.get(
        `${ENV.API_URL}/api/hdp/booking/v2${reservationQuery}`,
        {
          headers: { Authorization: token },
        }
      );

      const reservations = reservationsResponse.data.data.roomCategories;

      const roomsResponse = await axios.get(`${ENV.API_URL}/api/hdp/room`, {
        headers: { Authorization: token },
      });

      const fetchedRooms = roomsResponse.data.data;

      const roomsWithReservations = fetchedRooms.map((room) => {
        let reservationsForRoom = [];

        reservations.forEach((category) => {
          const foundRoom = category.rooms.find((r) => r.id === room.id);
          if (foundRoom && foundRoom.reservations) {
            reservationsForRoom = foundRoom.reservations.map((reservation) => ({
              ...reservation,
              notice: reservation.noticeData || { credits: 0, debits: 0 }, // Assign notice or fallback
            }));
          }
        });

        return { ...room, reservations: reservationsForRoom };
      });
      setRooms(roomsWithReservations);
    } catch (err) {
      toast.error(
        err?.response?.data?.errors[0]?.message || t("An error occurred")
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filterStatus, filterCategory, sortOrder]);

  const indexOfLastRoom = currentPage * roomsPerPage;
  const indexOfFirstRoom = indexOfLastRoom - roomsPerPage;
  const currentRooms = rooms
    .filter((room) => {
      if (searchTerm) {
        return room?.number?.toLowerCase().includes(searchTerm?.toLowerCase());
      }
      return true;
    })
    .filter((room) => {
      if (filterStatus) {
        return room.status_name === filterStatus;
      }
      return true;
    })
    .filter((room) => {
      if (filterCategory) {
        return room.category_name === filterCategory;
      }
      return true;
    })
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a.number - b.number;
      } else {
        return b.number - a.number;
      }
    });

  const roomsToShow = currentRooms.slice(indexOfFirstRoom, indexOfLastRoom);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mt-3">
      <h5>{t("All rooms")}</h5>

      <div>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={gridItemSize}>
            <TextField
              fullWidth
              type="number"
              label={t("Search by number")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={gridItemSize}>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t("Filter by status")}</InputLabel>
              <Select
                value={filterStatus}
                label={`${t("Filter by status")}`}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <MenuItem value="">{t("All")}</MenuItem>
                <MenuItem value="available">{t("Available")}</MenuItem>
                <MenuItem value="busy">{t("Busy")}</MenuItem>
                <MenuItem value="dirty">{t("Dirty")}</MenuItem>
                <MenuItem value="cleaning in progress">
                  {t("Cleaning in progress")}
                </MenuItem>
                <MenuItem value="in maintains">{t("In maintains")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={gridItemSize}>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t("Filter by category")}</InputLabel>
              <Select
                label={`${t("Filter by category")}`}
                value={filterCategory}
                onChange={(e) => setFilterCategory(e.target.value)}
              >
                <MenuItem value="">{t("All")}</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={gridItemSize}>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t("Sort by number")}</InputLabel>
              <Select
                label={`${t("Sort by number")}`}
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <MenuItem value="asc">{t("Ascending")}</MenuItem>
                <MenuItem value="desc">{t("Descending")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            {roomsToShow.length > 0 ? (
              roomsToShow.map((room) => (
                <Grid item key={room.id} xs={12} sm={6} md={4} lg={3}>
                  <Card data={room} categories={categories} fetchData={fetchData}/>
                </Grid>
              ))
            ) : (
              <p style={{marginTop:'20px'}}>{t("No rooms found")}</p>
            )}
          </Grid>
        )}

        {currentRooms.length > roomsPerPage && (
          <div className="pagination mt-3" style={{ textAlign: "center" }}>
            {[...Array(Math.ceil(currentRooms.length / roomsPerPage)).keys()].map(
              (number) => (
                <Button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  variant={currentPage === number + 1 ? "contained" : "outlined"}
                  color="primary"
                  style={{
                    margin: "0 5px",
                    minWidth: "40px",
                  }}
                >
                  {number + 1}
                </Button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DataTable;
