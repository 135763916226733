import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Empty } from "antd";
import settingIcon2 from "../../assets/setting.png";
import ShaxmatkaPult from "./Settings/Pult";
import ShaxmatkaSettings from "./Settings/Settings";
import ShaxmatkaGuests from "./Guests/Guests";
import ShaxmatkaItem from "./TableItem/Item";
import Overlay from "./Overlay";
import { useTranslation } from "react-i18next";
import ShaxmatkaBooking from "./Booking/Booking";
import ShaxmatkaCheckOut from "./CheckOut/CheckOut";
import ShaxmatkaCheckIn from "./CheckIn/CheckIn";
import ENV from "../../environment";
import ShaxmatkaItemShared from "./TableItem/ItemShared";
import { dateDDMMYYYY, dateLocalISO, dateMonth, dateWeekDay } from "../../hooks/dateFormatter";
const tableWidthTypes = ["weekly", "monthly", "10 days", "14 days", "20 days"];

const Shaxmatka = () => {
    const { t } = useTranslation();
    const [today, setToday] = useState(new Date());
    const [days, setDays] = useState([]);
    const [numberOfDays, setNumberOfDays] = useState(14);
    const [tableWidth, setTableWidth] = useState("14 days");
    const [categories, setCategories] = useState([]);
    const [bookingTypes, setBookingTypes] = useState([]);
    const [currentCategory, setCurrentCategory] = useState("all");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [fulldata, setFullData] = useState([]);
    const [checkInModal, setCheckInModal] = useState(false);
    const [bookingModal, setBookingModal] = useState(false);
    const [checkOutModal, setCheckOutModal] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);
    const [guestsModal, setGuestsModal] = useState(false);
    const [guests, setGuests] = useState([]);
    const [bookingData, setBookingData] = useState({});
    const [checkInData, setCheckInData] = useState({});
    const [checkOutData, setCheckOutData] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getCategories = async () => {
        await axios
            .get(`${ENV.API_URL}/api/hdp/room/category/list`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setCategories(res.data.data))
            .catch((err) => console.log(err));
    };

    const getBookingTypes = async () => {
        await axios
            .get(`${ENV.API_URL}/api/hdp/booking/types`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setBookingTypes(res.data.data))
            .catch((err) => console.log(err));
    };

    const getData = async () => {
        if (days.length > 0) {
            const query = `?start_date=${dateLocalISO(days[0])}&end_date=${dateLocalISO(days[days.length - 1])}`;
            setLoading(true);
            await axios
                .get(`${ENV.API_URL}/api/hdp/booking/v2${query}`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    console.log(res.data.data.roomCategories)
                    res.data.data.roomCategories.forEach((e) => e.rooms.forEach((i) => (i.category = { name: e.name, id: e.id })));
                    setFullData(res.data.data.roomCategories);
                    if (currentCategory === "all") setData(res.data.data.roomCategories.map((e) => e.rooms).flat());
                    else
                        setData(
                            res.data.data.roomCategories
                                .filter((e) => e.name === currentCategory)
                                .map((e) => e.rooms)
                                .flat()
                        );
                })
                .catch((err) => console.log(err));

            setLoading(false);
        }
    };

    useEffect(() => {
        getCategories();
        getBookingTypes();
    }, []);

    const nextDays = () => {
        const newDays = [];
        let newToday;
        if (tableWidth === "monthly") {
            newToday = new Date(new Date(today).getFullYear(), new Date(today).getMonth() + 1, 1);
            const newNumberOfDays = daysInMonth(newToday);
            newDays.push(newToday);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(newToday, i));
            setNumberOfDays(newNumberOfDays);
        } else {
            newToday = daysLater(today, numberOfDays);
            for (let i = 0; i < numberOfDays; i++) {
                newDays.push(daysLater(newToday, i));
            }
        }
        setToday(newToday);
        setDays(newDays);
    };

    const prevDays = () => {
        const newDays = [];
        let newToday;
        if (tableWidth === "monthly") {
            newToday = new Date(new Date(today).getFullYear(), new Date(today).getMonth() - 1, 1);
            const newNumberOfDays = daysInMonth(newToday);
            newDays.push(newToday);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(newToday, i));
            setNumberOfDays(newNumberOfDays);
        } else {
            newToday = daysAgo(today, numberOfDays);
            for (let i = 0; i < numberOfDays; i++) {
                newDays.push(daysLater(newToday, i));
            }
        }
        setToday(newToday);
        setDays(newDays);
    };

    useEffect(() => {
        if (days.length > 0) getData();
    }, [days]);

    const changeCategory = (e) => {
        setCurrentCategory(e.target.value);
    };

    const onBooking = (params) => {
        setBookingData(params);
        setBookingModal(true);
    };

    const onCheckIn = (params) => {
        setCheckInData(params);
        setCheckInModal(true);
    };

    const onTransfer = (booking, room) => {
        setCheckInData({ ...booking, room });
        setCheckInModal(true);
        // console.log(booking);
        // setTransferData({ ...booking, room });
        // setTransferModal(true);
    };

    const onCheckOut = (currentItems) => {
        const x = [];
        for (let i = 0; i < currentItems.length; i++) {
            if (currentItems[i].bookingStatus === 1) {
                const find = x.find((e) => e[0].booking_id === currentItems[i].booking_id);
                if (find) find.push(currentItems[i]);
                else x.push([currentItems[i]]);
            }
        }
        setCheckOutData(x);
        setCheckOutModal(true);
    };

    useEffect(() => {
        if (currentCategory === "all") setData(fulldata.map((e) => e.rooms).flat());
        else
            setData(
                fulldata
                    .filter((e) => e.name === currentCategory)
                    .map((e) => e.rooms)
                    .flat()
            );
    }, [currentCategory]);

    useEffect(() => {
        setSettingsModal(false);
        const newDays = [];
        let newNumberOfDays;
        let newToday;
        if (tableWidth === "10 days") {
            newToday = today;
            newNumberOfDays = 10;
            newDays.push(today);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "14 days") {
            newToday = today;
            newNumberOfDays = 14;
            newDays.push(today);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "20 days") {
            newToday = today;
            newNumberOfDays = 20;
            newDays.push(today);
            for (let i = 1; i < newNumberOfDays; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "monthly") {
            newNumberOfDays = daysInMonth(today);
            const current_date = new Date(today).getDate();
            newToday = daysAgo(today, current_date - 1);
            for (let i = 1; i < current_date; i++) newDays.push(daysAgo(today, current_date - i));
            newDays.push(today);
            for (let i = 1; i <= newNumberOfDays - current_date; i++) newDays.push(daysLater(today, i));
        }
        if (tableWidth === "weekly") {
            newNumberOfDays = 7;
            const current_date = new Date(today).getDay();
            newToday = daysAgo(today, current_date - 1);
            for (let i = 1; i < current_date; i++) newDays.push(daysAgo(today, current_date - i));
            newDays.push(today);
            for (let i = 1; i <= newNumberOfDays - current_date; i++) newDays.push(daysLater(today, i));
        }
        setToday(newToday);
        setNumberOfDays(newNumberOfDays);
        setDays(newDays);
    }, [tableWidth]);

    return (
        <div className={styles.fullPage} style={{ "--times": numberOfDays }}>
            <div className={styles.noDataContainer}>{t("This page is not intended for such a screen")}</div>
            <div className={styles.dataContainer}>
                {loading && <Overlay />}
                {data.length > 0 && (
                    <>
                        <ShaxmatkaCheckIn show={checkInModal} setShow={setCheckInModal} checkInData={checkInData} getData={getData} categories={categories} />
                        <ShaxmatkaBooking
                            show={bookingModal}
                            setShow={setBookingModal}
                            bookingTypes={bookingTypes}
                            getData={getData}
                            bookingData={bookingData}
                        />
                        <ShaxmatkaCheckOut show={checkOutModal} setShow={setCheckOutModal} getData={getData} checkOutData={checkOutData} />
                        <ShaxmatkaSettings
                            show={settingsModal}
                            setShow={setSettingsModal}
                            setTableWidth={setTableWidth}
                            tableWidth={tableWidth}
                            tableWidthTypes={tableWidthTypes}
                        />
                        <ShaxmatkaGuests show={guestsModal} setShow={setGuestsModal} guests={guests} data={data} />
                        <div className={styles.filter}>
                            <div className={styles.categoryContainer}>
                                <h5>{t("Category")}</h5>
                                <Box sx={{ width: 120 }}>
                                    <FormControl variant="standard" fullWidth>
                                        <Select id="demo-simple-select" value={currentCategory} onChange={changeCategory}>
                                            {categories.map((e) => (
                                                <MenuItem value={e.name}>{e.name}</MenuItem>
                                            ))}
                                            <MenuItem value={"all"}>{t("All")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className={styles.headerBlock1}>
                                <Button type="primary" onClick={() => setSettingsModal(true)}>
                                    <img src={settingIcon2} height={19} alt="setting" />
                                </Button>
                            </div>
                        </div>
                        <div className={styles.bigContainer}>
                            <div className={styles.bigContainerHead}>
                                <div>{todayMonth(days, t)}</div>
                            </div>
                            {days.map((e) => (
                                <div key={e} className={styles.dayContainer}>
                                    <div>
                                        {85 * numberOfDays + 400 < width
                                            ? dateDDMMYYYY(dateLocalISO(e))
                                            : 50 * numberOfDays + 400 < width
                                            ? dateDDMMYYYY(dateLocalISO(e)).slice(0, 5)
                                            : dateDDMMYYYY(dateLocalISO(e)).slice(0, 2)}
                                    </div>
                                    <div style={{ fontSize: "0.875em", color: "#66339995" }}>
                                        {85 * numberOfDays + 400 < width
                                            ? t(dateWeekDay(e))
                                            : 40 * numberOfDays + 400 < width
                                            ? t(dateWeekDay(e).slice(0, 3))
                                            : ""}
                                    </div>
                                </div>
                            ))}
                            {data.map((room) => (
                                <React.Fragment>
                                    <div className={styles.room} key={room.id} style={{ borderLeftColor: colorRoomStatus(room.status) }}>
                                        <div className={styles.roomNumber}>{room.number}</div>
                                        <div className={styles.roomCategory}>{room.category.name}</div>
                                    </div>
                                    {room.is_shared === false &&
                                        days.map((day) => (
                                            <ShaxmatkaItem
                                                data={room.reservations || []}
                                                day={day}
                                                setGuestsModal={setGuestsModal}
                                                today={today}
                                                setGuests={setGuests}
                                                setCheckInModal={setCheckInModal}
                                                onBooking={onBooking}
                                                onCheckIn={onCheckIn}
                                                onCheckOut={onCheckOut}
                                                onTransfer={onTransfer}
                                                days={days}
                                                room={{
                                                    id: room.id,
                                                    number: room.number,
                                                    number_of_people: room.number_of_people,
                                                    price: room.price,
                                                    category: room.category,
                                                }}
                                            />
                                        ))}

                                    {room.is_shared === true &&
                                        days.map((day) => (
                                            <ShaxmatkaItemShared
                                                data={room.reservations || []}
                                                day={day}
                                                setGuestsModal={setGuestsModal}
                                                today={today}
                                                setGuests={setGuests}
                                                setCheckInModal={setCheckInModal}
                                                onBooking={onBooking}
                                                onCheckIn={onCheckIn}
                                                onCheckOut={onCheckOut}
                                                onTransfer={onTransfer}
                                                days={days}
                                                room={{
                                                    id: room.id,
                                                    number: room.number,
                                                    number_of_people: room.number_of_people,
                                                    price: room.price,
                                                    category: room.category,
                                                }}
                                            />
                                        ))}
                                </React.Fragment>
                            ))}
                        </div>
                    </>
                )}
                {data.length > 0 && (
                    <div className={styles.pultContainer}>
                        <ShaxmatkaPult today={today} next={nextDays} prev={prevDays} />
                    </div>
                )}
                {data.length === 0 && !loading && <Empty description={t("No Data yet")} />}
            </div>
        </div>
    );
};

export default Shaxmatka;

function daysLater(today, difference) {
    return new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
}

function daysAgo(today, difference) {
    return new Date(today.getTime() - difference * 24 * 60 * 60 * 1000);
}

function todayMonth(days, t) {
    if (!days || days.length === 0) return "";
    return `${dateLocalISO(days[0]).slice(0, 4)}-${t(dateMonth(days[0]))}`;
}

function colorRoomStatus(status) {
    switch (status) {
        case "available":
            return "#cccccc";
        case "busy":
            return "#43a047";
        case "dirty":
            return "#757575";
        case "cleaning in progress":
            return "#fdd835";
        case "in maintains":
            return "#9ccc65";
        case "cleaning complete":
            return "#1e88e5";

        default:
            return "";
    }
}

function daysInMonth(date) {
    return new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDate();
}
